<template>
    <div class="analysis" :class="parentClass">
        <div class="analysis__control label-move" :class="">
            <input class="analysis__input" :class="{'has-content': analysisUrl!==''}" type="text" placeholder="" v-model="analysisUrl" >
            <input v-if="isCompetition" class="analysis__input" :class="{'has-content': competeUrl!==''}" type="text" placeholder="" v-model="competeUrl" >
            <p class="analysis__des">{{ getDescription }}</p>
        </div>
        <div class="clearfix" v-if="isCompetition"></div>
        <a class="analysis__btn normal-btn" href="javascript:;" @click="startAnalysis">立即檢測</a>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        data(){
            return {
                analysisUrl: '',
                competeUrl: '',
            }
        },
        props: {
            parentClass: ''
        },
        computed: {
            ...mapGetters({
                analysisData: 'getAnalysis'
            }),
            isCompetition(){
                return this.parentClass.match(/\analysis--competition$/)
            },
            getDescription(){
                return (this.isCompetition)? '輸入雙邊網址，立即檢測':'輸入網址，立即檢測'
            }
        },
        methods: {
            ...mapActions([
                'actionAnalysis'
            ]),
            startAnalysis(){
                this.$store.dispatch('startLoading')
                setTimeout(()=>{
                    if(this.isCompetition){
                        this.$store.dispatch('actionCompete', [this.analysisUrl, this.competeUrl]).then(()=>{
                            this.$router.push('/competition')
                            this.$store.dispatch('endLoading')
                        })
                    }else{
                        this.$store.dispatch('actionAnalysis', this.analysisUrl).then(()=>{
                            this.$router.push('/result')
                            this.$store.dispatch('endLoading')
                        })
                    }
                }, 3000)




            }
        },
        watch:{
            $route(to, from){
                if(to!==from){
                    this.analysisUrl = ''
                    this.competeUrl = ''
                }
            }
        }
    }
</script>