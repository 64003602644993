import Vue from 'vue'
import * as types from '../mutations_type'

// states

export const state = {
    contact: {
        name: '',
        mail: '',
        tel: '',
        service:'',
        content: '',
        finished: false
    },
    faq:[],
    pickFaq: {},
    loginState: {},
    regState: {},
    activeState: {},
    resendState: {},
    uploadState: {}
}

export const mutations = {
    [types.CONTACT](state, data) {
        state.contact = {
            name: data.name,
            mail: data.mail,
            tel: data.phone,
            service: data.service,
            content: data.content,
            finished: data.finished
        }
    },
    [types.FAQ](state, data) {
        state.faq = data
    },
    [types.PICK_FAQ](state, data){
        state.pickFaq = data
    },
    [types.LOGIN](state, data){
        //state.token = data.token
        state.loginState = data

    },
    [types.REGISTRATION](state, data){
        state.regState = data
    },
    [types.ACTIVE](state, data){
        state.activeState = data
    },
    [types.RESEND_AUTH_CODE](state, data){
        state.resendState = data
    },
    [types.UPLOAD_PHOTO](state, data){
        state.uploadState = data
    }
}