<template>

    <div class="wrap">
        <header class="header">
            <div class="inner">
                <div class="header__container">
                    <div class="header__main"><router-link class="logo" to="{path: '/'}"><img src="./assets/images/SEOO-logo.svg" alt="SEOO"></router-link>
                        <nav class="menu" :class="{'opened':openMenu}">
                            <h2 class="hide-title">SEOO 導覽選單</h2>
                            <ul class="menu__list">
                                <li class="menu__item"><router-link class="menu__link" :to="{name: 'start'}">SEO分析</router-link></li>
                                <li class="menu__item"><router-link class="menu__link" :to="{name: 'startCompetition'}">雙網分析</router-link></li>
                                <li class="menu__item mb-hide"><a class="menu__link" href="premium.html">購買方式</a></li>
                                <li class="menu__item pc-hide"><a class="menu__link" href="compare.html">檢測實例</a></li>
                                <li class="menu__item pc-hide"><a class="menu__link" href="compare.html">SEO服務</a></li>
                                <li class="menu__item pc-hide"><a class="menu__link" href="compare.html">訂閱方案</a></li>
                                <hr class="pc-hide">
                                <li class="menu__item pc-hide"><a class="menu__link" href="">會員專區</a></li>
                                <li class="menu__contact pc-hide">
                                    <p class="menu__contact-label">聯絡我們</p>
                                    <p class="menu__contact-links">
                                        <a href=""><i class="fab fa-facebook"></i></a>
                                        <a href=""><i class="fab fa-line"></i></a>
                                        <a href=""><i class="fas fa-envelope"></i></a>
                                    </p>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="header__sub"><router-link class="header__login mb-hide" :to="{name: 'member'}">會員登入</router-link><a href="javascript:;" @click="toggleMenu" class="menu__open pc-hide" :class="{'opened':openMenu}"><i class="fas"></i></a></div>
                </div>
            </div>
        </header>
        <router-view :mq="mq" :open-menu="openMenu"></router-view>
        <main-footer></main-footer>
        <div class="loading"></div>
    </div>

</template>
<script>
    import Swiper from 'swiper'
    import mainFooter from './components/footer.vue'

    export default {
        data() {
            return {
                openMenu: false,
                mq: ''
            }
        },
        components:{
            mainFooter
        },
        methods: {
            toggleMenu(){
                this.openMenu = !this.openMenu
                this.changeScroll(!this.openMenu)
            },
            resetMenu(){
                this.openMenu = false
                this.changeScroll(!this.openMenu)
            },
            changeScroll(scroll){
                if(!scroll){
                    document.querySelector('body').classList.add('no-scroll');
                }else{
                    document.querySelector('body').classList.remove('no-scroll');
                }
            }
        },
        beforeMount(){
            this.mq = this.$mq;
            window.addEventListener('resize', (e)=>{
                if(this.mq != this.$mq && this.openMenu) this.toggleMenu()
                this.mq = this.$mq;
            });
        },
        mounted(){
            document.querySelectorAll('.menu__link').forEach((item, key)=>{
                item.addEventListener('click', this.resetMenu)
            });

        }
    }



</script>