//import Vue from 'vue';
import {state, mutations} from './mutations.js';
import * as types from '../mutations_type.js';
//import axios from 'axios';

const apiBaseUrl = '/api/'

export const getters = {
    getArticle(state) {
        return state.article
    }
}

export const actions = {
    actionGetArticle({commit}, articleName){
        articleName = 'static_page/anchor/' + articleName
        axios.get(apiBaseUrl + articleName)
            .then(function(response){
                console.log(response.data)
                commit(types.ARTICLE, response.data)
            })
            .catch(function(error){
                console.error(error);
            });

    }
}