<template>
    <div class="lightbox" :class="{'show': showBox === boxId}">
        <div class="lightbox__cover"></div>
        <div class="lightbox__box" :class="{'lightbox__box--large': boxSize === 'large'}">
            <div class="lightbox__inner">
                <h2 class="lightbox__title">{{ boxTitle }}</h2>
                <div class="lightbox__msg">
                    <slot name="msg"></slot>
                </div>
                <div class="button-line" :class="{'button-line--between': buttonLineClass==='between', 'button-line--align-center': buttonLineClass==='center'}">
                <slot name="buttons">
                    <a href="javascript:;" class="btn normal-btn normal-btn--40 normal-btn--reverse" @click="closeBox">取消</a>
                    <a href="javascript:;" class="btn normal-btn normal-btn--40" @click="closeBox">確定</a>
                </slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            boxId: String,
            boxTitle: String,
            showBox: String,
            large: Boolean,
            boxSize: String,
            buttonAlign: String
        },
        methods:{
            closeBox(){
                this.$emit('closebox')
            }
        },
        computed:{
            buttonLineClass: function(){
                return (typeof this.buttonAlign == 'undefined')? 'between': this.buttonAlign
            }
        },
        updated(){
            if(this.showBox === this.boxId){
                this.$root.$children[0].changeScroll(false)
            }else{
                this.$root.$children[0].changeScroll(true)
            }
        }
    }
</script>