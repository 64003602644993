<template>
    <div class="content">
        <hero>聯絡我們</hero>
        <div class="user section">
            <div class="section__content section__content--padding-tb">
                <div class="inner">
                    <div v-if="!finished" class="user__edit user__edit--contact">
                        <div class="user__upper text-center">
                            <h2 class="user__title block__title block__title--noline block__title--nopadding">需要解答？快和我們聯絡！</h2>
                            <p class="member__msg text-center">&nbsp;</p>
                        </div>
                        <div class="user__profile user__profile--contact">
                            <div class="user__edit-form">
                                <form class="form">
                                    <ul class="form__list">
                                        <li class="form__item" :class="{'form__item--warning': errors.has('name')}">
                                            <label for="name" class="form__label">姓名</label>
                                            <input type="text" class="form__input" name="name" placeholder="" v-model="name" v-validate="'required'">
                                            <p class="form__under">{{ errors.first('name') }}</p>
                                        </li>
                                        <li class="form__item" :class="{'form__item--warning': errors.has('email')}">
                                            <label for="email" class="form__label">Email</label>
                                            <input type="email" class="form__input" v-model="mail" placeholder="" name="email" v-validate="'required|email'">
                                            <p class="form__under">{{ errors.first('email') }}</p>
                                        </li>
                                        <li class="form__item" :class="{'form__item--warning': errors.has('mobile')}">
                                            <label for="phone" class="form__label">手機號碼</label>
                                            <input type="phone" name="mobile" class="form__input" placeholder="" v-model="tel" v-validate="'required|mobile|digits:10'">
                                            <p class="form__under">{{ errors.first('mobile') }}</p>
                                        </li>
                                        <li class="form__item">
                                            <label for="phone" class="form__label">留言類型</label>
                                            <div class="form__select">
                                                <div class="custom-select">
                                                    <input class="custom-select__input" type="text" v-model="service">
                                                    <select class="custom-select__select">
                                                        <option>{{service}}</option>
                                                        <option>{{service}}</option>
                                                    </select>
                                                    <i class="fas fa-caret-down"></i>
                                                </div>
                                            </div>
                                            <p class="form__under"></p>
                                        </li>
                                        <li class="form__item" :class="{'form__item--warning': errors.has('commentContent')}">
                                            <label for="" class="form__label">聯絡內容</label>
                                            <textarea name="commentContent" id="commentContent" class="form__textarea" v-model="content" v-validate="'required'"></textarea>
                                            <p class="form__under">{{ errors.first('commentContent') }}</p>
                                        </li>
                                    </ul>
                                    <p class="button-line button-line--align-center button-line--underform">
                                        <a @click="sendContact" href="javascript:;" class="btn normal-btn">送出</a>
                                    </p>

                                </form>
                            </div>
                        </div>
                    </div>
                    <article v-else class="article text-center">
                        <h2 class="article__title">感謝您的聯繫</h2>
                        <div class="article__content">我們已收到您的留言，會請客服人員儘快與您聯繫。</div>
                    </article>
                </div>
            </div>
        </div>
        <footer-analysis v-if="finished"></footer-analysis>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import footerAnalysis from '../components/footerAnalysis.vue'
    import hero from './../components/hero.vue'

    export default {
        data() {
            return {
                name: '',
                mail: '',
                tel: '',
                content: '',
                finished: false,
                service: 'SEOO相關'
            }
        },
        components: {
            hero,
            footerAnalysis
        },
        computed:{
            ...mapGetters({
                getContact: 'getContact',
            }),
        },
        methods:{
            ...mapActions([
                'actionContact'
            ]),
            sendContact: function(){
                this.$validator.validateAll().then((result)=>{
                    if(result) this.$store.dispatch('actionContact', this._data)
                })
            }
        },
        mounted(){
            this.finished = false
        },
    }
</script>