import Vue from 'vue'
import Vuex from 'vuex'
//import * as getters from './getters.js';
import analysis from './analysis'
import article from './article'
import base from './base'
import {getters, state, actions, mutations} from './root.js'

Vue.use(Vuex);



export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules:{
        analysis,
        article,
        base
    },
    strict: true
});