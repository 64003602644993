<template>
    <div class="visual">
        <div class="visual__banner">
            <picture>
                <source srcset="./../assets/images/concept-320.png" :media="atSmall"><img srcset="./../assets/images/concept-1868728_1920.png" alt="">
            </picture>
        </div>
        <div class="visual__content">
            <div class="inner">
                <h2 v-if="!competition" class="visual__title">想了解公司網站<br class="pc-hide">運作成效？</h2>
                <h2 v-else class="visual__title">了解對手，勝券在握</h2>

                    <label-move-input :parent-class="`analysis--visual ${competition ? 'analysis--competition':''}`"></label-move-input>
                    <a v-if="!competition" class="analysis__link" href="">什麼是SEO？</a>

            </div>
        </div>
    </div>
</template>
<script>
    import labelMoveInput from './labelMoveInput.vue'

    export default {
        data(){
            return {
                atSmall: '(max-width:640px)'
            }
        },
        props:{
            competition: Boolean
        },
        components: {
            labelMoveInput
        },
    }
</script>