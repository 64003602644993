<template>
    <div class="content" id="top">
        <hero>SEO分析</hero>
        <side-nav></side-nav>
        <div class="result">
            <!-- overview -->
            <div class="section section--even section--nopadding-atsmall">
                <div class="section__content section__content--padding-tb section__content--atsmall-bg-white">
                    <div class="section__head section__head--nopadding">
                        <div class="inner">
                            <p class="result__date"><i class="far fa-calendar-alt"></i>2018-05-13 18:00:00</p>
                            <h3 class="section__title section__title--noline result__name">nowilldesign.com</h3>
                        </div>
                    </div>
                </div>
                <div class="section__content section__content--padding-b section__content--padding-t-atsmall">
                    <div class="result__overview">
                        <div class="inner flexbox flexbox--around flexbox--v-top flexbox--column-atsmall">
                            <div class="result__shot flexbox__col flexbox__col--5">
                                <img src="./../assets/images/pc.png" alt="" class="result__frame--pc">
                                <div class="result__shot-pc" :style="{ backgroundImage: `url(${require('./../assets/images/shot-pc.png')})` }"></div>
                                <img src="./../assets/images/mobile.png" alt="" class="result__frame--mb">
                                <div class="result__shot-mb" :style="{ backgroundImage: `url(${require('./../assets/images/shot-mb.png')})` }"></div>
                            </div>
                            <div class="result__scores flexbox__col flexbox__col--5">
                                <div class="result__score-block result__score-block--b">
                                    <p class="result__score-label"><span class="icon"><img src="./../assets/images/icon-seo-3.svg" alt="SEOO"></span>評分</p>
                                    <p class="result__score">
                                        <span class="get">60</span><span class="max">/100</span>
                                    </p>
                                </div>
                                <p class="result__compete-msg">
                                    <span class="icon"><img src="./../assets/images/face2.svg" alt="seoo"></span>
                                    <span class="result__compete-bubble">
                                        想了解更多？<br class="pc-hide">來<router-link class="inline-link inline-link--lighter" :to="{path: '/start-competition'}">雙網分析</router-link>了解競爭對手吧
                                    </span>
                                </p>
                                <p class="share-links">
                                    <span class="share-label">分享：</span>
                                    <a href=""><i class="fab fa-facebook"></i></a>
                                    <a href=""><i class="fas fa-envelope"></i></a>
                                    <a href=""><i class="fas fa-link fa-flip-horizontal"></i></a>
                                </p>
                                <p class="button-line button-line--2btns">
                                    <a class="btn small-btn" href="javascript:;" @click="showBox = 'payPointBox'">檢視完整版結果<span class="sub">（1點）</span></a>
                                    <a class="btn small-btn" href="./compare">與上次結果比較<span class="sub">（1點）</span></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- radar -->
            <div class="section section--result section--nopadding-atsmall">
                <div class="section__content section__content--padding-tb">
                    <div class="inner inner--radar flexbox flexbox--around flexbox--v-center flexbox--column-atsmall flexbox--reverse-atsmall">
                        <div class="flexbox__col flexbox__col--5 radar__wrap">
                            <radar radar-name="radar--overview" :radar-data="analysisData"></radar>
                        </div>
                        <div class="flexbox__col flexbox__col--5">
                            <div class="radar__detail">
                                <h3 class="radar__title section__title section__title--noline section__title--margin-b section__title--center-atsmall">SEO為什麼重要</h3>
                                <p class="radar__desction para">她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了然靜成，中天業…… 她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……</p>
                            </div>
                            <div class="radar__bars">
                                <p class="radar__bars-title">SEO現況</p>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--good">
                                        <i class="fas fa-check fa-fw"></i>好東西<span class="radar__percent">5%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--good" data-percent="30"><span class="bar"></span></span>
                                </div>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--normal">
                                        <i class="fas fa-exclamation fa-fw"></i>待改進<span class="radar__percent">25%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--normal" data-percent="70"><span class="bar"></span></span>
                                </div>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--worse">
                                        <i class="fas fa-times fa-fw"></i>有問題<span class="radar__percent">100%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--worse" data-percent="50"><span class="bar"></span></span>
                                </div>
                            </div>
                            <p class="button-line button-line--align-right">
                                <a href="" class="small-btn btn btn--full-atsmall">了解更多</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- seo -->
            <div class="section section--toggle section--padding-b-less" id="seo">
                <h2 class="section__title section__title--toggle section__title--noline" @click="toggleSection"><span class="section__title-text">SEO<span class="section__title-bubble">她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了……</span></span></h2>
                <div class="section__content section__content--padding-tb section__content--toggle unfold">
                    <div class="inner">
                        <div class="data-table">
                            <div class="data-table__row data-table__row--head flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2"><span class="name-label">項目</span></div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium text-center">影響度</div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium text-center">難易度</div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">評分敘述</div>
                            </div>

                            <div class="data-table__help data-table__help--sitetitle">
                                <p class="data-table__content data-table__content--help">
                                        她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了然靜成，中天業…… 她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……
                                </p>
                            </div>

                            <div class="data-table__row flexbox flexbox--column-atsmall flexbox--nomargin-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon" @click="toggleHelp" data-help="data-table__help--sitetitle"><i class="far fa-question-circle"></i></span>
                                        標題名稱
                                        <span class="data-table__state"><i class="fas fa-check"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">影響度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">難易度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light"></span>
                                            <span class="data-table__light data-table__light"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__item-name">評分敘述</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__content">品禾全端網路工作室 | 專業RWD跨平台網站與購物車建置專家</p>
                                        <p class="data-table__sub-content">Length: 30 character(s)</p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--column-atsmall flexbox--nomargin-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2 flexbox__col--2-atmedium">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Meta敘述
                                        <span class="data-table__state"><i class="fas fa-times"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">影響度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">難易度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light"></span>
                                            <span class="data-table__light data-table__light"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__item-name">評分敘述</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__content">Pinnacle Network Studio 品禾全端稟著提供客戶最專業的服務之念頭，多年來跨足了多項網站相關領域，舉凡架構、設計、企劃、PHP程式、社群、行銷、使用者研究等等，皆有3~5年不等的實戰經驗，是個對網路充滿熱情與專注的專業設計師</p>
                                        <p class="data-table__sub-content">Length: 122 character(s)</p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--column-atsmall flexbox--nomargin-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Meta關鍵字
                                        <span class="data-table__state"><i class="fas fa-exclamation"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">影響度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">難易度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light"></span>
                                            <span class="data-table__light data-table__light"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__item-name">評分敘述</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__content">品禾全端,響應式網站設計,rwd網頁設計,台北rwd網站設計,台北響應式網站設計,主機代管,PHP程式設計,網站行銷</p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--column-atsmall flexbox--nomargin-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        標題
                                        <span class="data-table__state"><i class="fas fa-exclamation"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">影響度</p>
                                    <div class="data-table__col-inner">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                    </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">難易度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light"></span>
                                            <span class="data-table__light data-table__light"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium data-table__col--block-atsmall">
                                    <p class="data-table__item-name data-table__item-name--block-atsmall">評分敘述</p>
                                    <div class="data-table__col-inner data-table__col-inner--block-atsmall">
                                        <table class="data-table__table data-table__table--border-between text-center">
                                            <tr>
                                                <td>&lt;H1&gt;</td>
                                                <td>&lt;H2&gt;</td>
                                                <td>&lt;H3&gt;</td>
                                                <td>&lt;H4&gt;</td>
                                                <td>&lt;H5&gt;</td>
                                                <td>&lt;H6&gt;</td>
                                            </tr>
                                            <tr>
                                                <td>0</td>
                                                <td>5</td>
                                                <td>4</td>
                                                <td>0</td>
                                                <td>2</td>
                                                <td>0</td>
                                            </tr>
                                        </table>
                                        <ul class="data-table__tb-list data-table__tb-list--odd data-table__tb-list--toggle">
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                        </ul>
                                        <p class="data-table__content text-center">
                                            <more-link></more-link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--column-atsmall flexbox--nomargin-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Google預覽
                                        <span class="data-table__state"><i class="fas fa-check"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">影響度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">難易度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light"></span>
                                            <span class="data-table__light data-table__light"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__item-name">評分敘述</p>
                                    <div class="data-table__col-inner">
                                    <p class="data-table__content text-center--atsmall">登入以獲取更多資料<a href="javascript:;" class="btn btn--unlock small-btn btn--inline"><i class="fas fa-unlock"></i> 解鎖此項目</a></p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--column-atsmall flexbox--nomargin-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                       Alt Attribute
                                        <span class="data-table__state"><i class="fas fa-check"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">影響度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__item-name">難易度</p>
                                    <div class="data-table__col-inner">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light"></span>
                                            <span class="data-table__light data-table__light"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__item-name">評分敘述</p>
                                    <div class="data-table__col-inner">
                                    <p class="data-table__content text-center--atsmall">登入以獲取更多資料<a href="javascript:;" class="btn btn--unlock small-btn btn--inline"><i class="fas fa-unlock"></i> 解鎖此項目</a></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 易用性 -->
            <div class="section section--toggle" id="usability">
                <h2 class="section__title section__title--toggle section__title--noline" @click="toggleSection">易用性</h2>
                <div class="section__content section__content--padding-tb section__content--toggle">
                    <div class="inner">
                        <div class="data-table">
                            <div class="data-table__row data-table__row--head flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2"><span class="name-label">項目</span></div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium text-center">影響度</div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium text-center">難易度</div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">評分敘述</div>
                            </div>

                            <div class="data-table__row flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        標題名稱
                                        <span class="data-table__state"><i class="fas fa-check"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light"></span>
                                        <span class="data-table__light data-table__light"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__content">品禾全端網路工作室 | 專業RWD跨平台網站與購物車建置專家</p>
                                    <p class="data-table__sub-content">Length: 30 character(s)</p>
                                </div>
                            </div>

                            <div class="data-table__row flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2 flexbox__col--2-atmedium">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Meta敘述
                                        <span class="data-table__state"><i class="fas fa-times"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light"></span>
                                        <span class="data-table__light data-table__light"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__content">Pinnacle Network Studio 品禾全端稟著提供客戶最專業的服務之念頭，多年來跨足了多項網站相關領域，舉凡架構、設計、企劃、PHP程式、社群、行銷、使用者研究等等，皆有3~5年不等的實戰經驗，是個對網路充滿熱情與專注的專業設計師</p>
                                    <p class="data-table__sub-content">Length: 122 character(s)</p>
                                </div>
                            </div>

                            <div class="data-table__row flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Meta關鍵字
                                        <span class="data-table__state"><i class="fas fa-exclamation"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light"></span>
                                        <span class="data-table__light data-table__light"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__content">品禾全端,響應式網站設計,rwd網頁設計,台北rwd網站設計,台北響應式網站設計,主機代管,PHP程式設計,網站行銷</p>
                                </div>
                            </div>

                            <div class="data-table__row flexbox">
                                    <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                        <p class="data-table__label">
                                            <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                            Meta關鍵字
                                            <span class="data-table__state"><i class="fas fa-exclamation"></i></span>
                                        </p>
                                    </div>
                                    <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                        </p>
                                    </div>
                                    <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light"></span>
                                            <span class="data-table__light data-table__light"></span>
                                        </p>
                                    </div>
                                    <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                        <table class="data-table__table data-table__table--border-between text-center">
                                            <tr>
                                                <td>&lt;H1&gt;</td>
                                                <td>&lt;H2&gt;</td>
                                                <td>&lt;H3&gt;</td>
                                                <td>&lt;H4&gt;</td>
                                                <td>&lt;H5&gt;</td>
                                                <td>&lt;H6&gt;</td>
                                            </tr>
                                            <tr>
                                                <td>0</td>
                                                <td>5</td>
                                                <td>4</td>
                                                <td>0</td>
                                                <td>2</td>
                                                <td>0</td>
                                            </tr>
                                        </table>
                                        <ul class="data-table__tb-list data-table__tb-list--odd data-table__tb-list--toggle">
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                        </ul>
                                        <p class="data-table__content text-center">
                                            <more-link></more-link>
                                        </p>
                                    </div>
                                </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <lightbox box-title="點數扣取" :show-box="showBox" box-id="payPointBox" @closebox="showBox = 'none'">
            <template slot="msg">
                <p>點擊確認後，即將扣取 <span class="hlight">1點</span> 並進行網站分析動作。</p>
            </template>
        </lightbox>
        <footer-analysis></footer-analysis>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex'
    import Vue from 'vue'
    import hero from './../components/hero.vue'
    import radar from './../components/radar.vue'
    import sideNav from './../components/sidenav.vue'
    import lightbox from './../components/lightbox.vue'
    import footerAnalysis from '../components/footerAnalysis.vue'
    import { slideDown, slideUp, slideStop, isVisible } from 'slide-anim';
    import scrollTo from 'animated-scroll-to'

    const moreLink = Vue.component('more-link', {
        data(){
            return{
                status: 'more'
            }
        },
        computed:{
            isMore(){
                return status == 'more'
            }
        },
        template: '<a href="javascript:;" class="inline-link inline-link--more" @click="toggleList">{{ status=="more" ? "看更多":"收回" }} <i class="fas" :class="{'+"'fa-plus-circle': status=='more','fa-minus-circle': status=='less'"+'}"></i></a>',
        //delimiters: ['${', '}'],
        methods:{
            toggleList(e){
                let toggle = e.currentTarget.parentNode.previousElementSibling
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    this.status = 'more'
                }else{
                    toggle.classList.add('unfold')
                    this.status = 'less'
                }
                setTimeout(()=>{
                    scrollTo(toggle, {
                        offset: -60
                    })
                }, 100)
            },
        }
    })
    export default {
        data() {
            return {
                //analysisData: []
                showBox: 'none'
            }
        },
        computed: {
            ...mapGetters({
                analysisData: 'getAnalysis'
            }),

        },
        components:{
            hero,
            radar,
            moreLink,
            sideNav,
            lightbox,
            footerAnalysis
        },
        mounted(){
            document.querySelectorAll('.radar__bar-body .bar').forEach(function(item, key){
                item.style.width = item.parentNode.getAttribute('data-percent') + '%'
            })
            document.querySelectorAll('.section__content--toggle').forEach(function(item, key){
                if(!item.classList.contains('unfold')) item.style.display = 'none'
            })
            let radar = document.querySelector('.radar__wrap')
            let detail = document.querySelector('.radar__detail').parentElement
            let wrap = document.querySelector('.inner--radar')
            window.addEventListener('resize', function(){
                if(window.innerWidth<=780){
                    detail.insertBefore(radar, detail.querySelector('.radar__bars'))
                }else{
                    wrap.insertBefore(radar, wrap.firstChild)
                }
            })
            window.dispatchEvent(new Event('resize'))
        },
        methods:{

            toggleSection(e){
                let toggle = e.currentTarget.nextElementSibling
                let _this = e.currentTarget
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    slideUp(toggle)
                }else{
                    toggle.classList.add('unfold')
                    slideDown(toggle)
                }
                setTimeout(()=>{
                    scrollTo(_this, {
                        offset: -60
                    })
                }, 100)
            },
            toggleHelp(e){
                let toggle = document.querySelector('.'+e.currentTarget.getAttribute('data-help'))
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    slideUp(toggle)
                }else{
                    toggle.classList.add('unfold')
                    slideDown(toggle)
                }
            }
        },
        watch:{
            analysisData: ()=>{
                scrollTo(0)
            }
        },
    }
</script>