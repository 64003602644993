<template>
    <div class="content">
        <hero>常見問題</hero>
        <div class="section">
            <div class="inner">
                <div class="section__content section__content--padding-tb">
                    <h2 class="article__title">{{ nowList.cateName }}</h2>
                    <div class="section__2col">
                        <div class="sidenav sidenav--stand show">
                            <ul class="sidenav__list">
                                <li class="sidenav__item" v-for="(item, index) in faqlist" >
                                    <a href="javascript:;" class="sidenav__link sidenav__link--stand" :class="(item == faqlist[nowCate]) ? 'on' : ''"  :data-id="index" @click="showCate">{{ item.cateName }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="section__main section__main--right">
                            <div class="inner inner--sub">
                                <div class="faq">
                                    <dl class="faq__list">
                                        <template v-for="(item, key, index) in nowList.list">
                                        <dt class="faq__ques" @click="toggleAns">{{ item.title }}</dt>
                                        <dd class="faq__ans" :data-no="key" :class="key==0 ? 'unfold' : ''" :style="{display: key==0 ? 'block' : 'none'}">
                                            <article class="article" v-html="item.content">
                                            </article>
                                        </dd>
                                        </template>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Vue from 'vue'
    import hero from './../components/hero.vue'
    import { slideDown, slideUp, slideStop, isVisible } from 'slide-anim'
    import removeClass from './../assets/js/moudles/removeClass.js'
    import scrollTo from 'animated-scroll-to'

    export default{
        data(){
            return {
                nowCate: 0,
                cateName: '',
            }
        },
        components:{
            hero
        },
        computed:{
            ...mapGetters({
                faqlist: 'getFaq',
                nowList: 'pickFaq'
            }),
        },
        beforeCreate(){
            //actionGetArticle('privacy')
            this.$store.dispatch('actionFaq')
            this.$store.dispatch('actionPickFaq', 0)
        },
        methods:{
            ...mapActions([
                'actionFaq',
                'actionPickFaq'
            ]),
            showCate(e){
                let _this = e.currentTarget
                removeClass('.sidenave__link', 'on')
                this.nowCate = _this.getAttribute('data-id')
                this.$store.dispatch('actionPickFaq', this.nowCate)
                _this.classList.add('on')
            },
            toggleAns(e){
                let toggle = e.currentTarget.nextElementSibling
                let _this = e.currentTarget
                document.querySelectorAll('.faq__ans').forEach(function(item, key){
                    if(item != toggle){
                        item.classList.remove('unfold')
                        slideUp(item)
                    }
                })
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    slideUp(toggle)
                }else{
                    toggle.classList.add('unfold')
                    slideDown(toggle)
                }
            },
        },
        watch:{
            nowList: ()=>{
                scrollTo(0)
                document.querySelectorAll('.faq__ans').forEach(function(item, key){
                    if(key==0){
                        item.classList.add('unfold')
                        item.style.display = 'block'
                    }else{
                        item.classList.remove('unfold')
                        item.style.display = 'none'
                    }
                })
            }
        },
    }

</script>