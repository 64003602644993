
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMq from 'vue-mq'
import store from './store';



import App from './App.vue'

import 'normalize.css'
import 'swiper/dist/css/swiper.css'
import './../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import './assets/css/style.less'


/* validate */
import VeeValidate, { Validator } from 'vee-validate'
import zh_TW from 'vee-validate/dist/locale/zh_TW'

Validator.localize('zh_TW', zh_TW);

Validator.extend("mobile", {
    getMessage: field => "手機格式不正確",
    validate: value => /^09[0-9]{8}$/.test(value)
})
Validator.extend("password", {
    getMessage: field => "請輸入正確格式的密碼",
    validate: value => /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{8,}$/.test(value)
})
const dictionary = {
    zh_TW: {
        messages: {
            email: () => '請輸入正確格式的 email',
            required: (field) => '此欄位必填'
        },

    }
}



Validator.localize(dictionary)


Vue.use(VeeValidate, {
    locale: 'zh_TW',
    events: 'change|blur',
})


/* router */
Vue.use(VueRouter)
import Index from './pages/Index.vue'
import Start from './pages/Start.vue'
import Result from './pages/Result.vue'
import Collection from './pages/Collection.vue'
import Competition from './pages/Competition.vue'
import Member from './pages/Member.vue'
import MemberUser from './pages/Member-user.vue'
import MemberPayment from './pages/Mamber-payment.vue'
import MemberExpense from './pages/Member-expense.vue'
import MemberRecord from './pages/Member-record.vue'
import MemberRegister from './pages/Member-register.vue'
import MemberPassword from './pages/Member-pwd.vue'
import Charge from './pages/Charge.vue'
import Article from './pages/Article.vue'
import Contact from './pages/Contact.vue'
import Faq from './pages/Faq.vue'

function getAbsolutePath() {
    let path = location.pathname.substring(0, location.pathname.lastIndexOf('/') + 1)
    if (path == '/') path = ''
    return path
}
const router = new VueRouter({
    // use html5 (沒有 hash, ex: #/hello)
    mode: 'history',
    //base: __dirname,
    base: __dirname + getAbsolutePath(),
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    //router 表
    routes: [
        {
            path: '/',
            name: 'index',
            component: Index,
            props: true,
            meta: { requiresAuth: false }, // 需驗證
        },
        {
            path: '/start',
            name: 'start',
            component: Start,
            props: true,
            meta: {
                requiresAuth: false,
                startType: 'single'
            }, // 需驗證
        },
        {
            path: '/start-competition',
            name: 'startCompetition',
            component: Start,
            props: true,
            meta: {
                requiresAuth: false,
                startType: 'competition'
            }, // 需驗證
        },
        {
            path: '/result',
            name: 'result',
            component: Result,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/compare',
            name: 'compare',
            component: Competition,
            props: true,
            meta: {
                requiresAuth: false,
                competeTarget: 'last'
            }, // 需驗證
        },
        {
            path: '/competition',
            name: 'competition',
            component: Competition,
            props: true,
            meta: {
                requiresAuth: false,
                competeTarget: 'another'
            }, // 需驗證
        },
        {
            path: '/collection',
            name: 'collection',
            component: Collection,
            props: true,
        },
        {
            path: '/member',
            name: 'member',
            component: Member,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/member-register',
            name: 'member-register',
            component: MemberRegister,
            props: true,
            meta: {
                active: false,
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/member-forgetpwd',
            name: 'member-forgetpwd',
            component: MemberPassword,
            props: true,
            meta: {
                type: 'forget',
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/member-resetpwd',
            name: 'member-resetpwd',
            component: MemberPassword,
            props: true,
            meta: {
                type: 'reset',
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/member-user',
            name: 'member-user',
            component: MemberUser,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/member-payment',
            name: 'member-payment',
            component: MemberPayment,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/member-expense',
            name: 'member-expense',
            component: MemberExpense,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/member-record',
            name: 'member-record',
            component: MemberRecord,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/charge',
            name: 'charge',
            component: Charge,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Article,
            props: true,
            meta: {
                articleName: 'privacy',
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/terms',
            name: 'terms',
            component: Article,
            props: true,
            meta: {
                articleName: 'userterms',
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/faq',
            name: 'faq',
            component: Faq,
            props: true,
            meta: {
                requiresAuth: false,
            }, // 需驗證
        },
        {
            path: '/active',
            name: 'active',
            component: MemberRegister,
            props: true,
            meta: {
                active: true,
                requiresAuth: false,
            }, // 需驗證
        },
        //轉址
        { path: '/*', redirect: '/' }
    ]
})


/* vue mq */

Vue.use(VueMq, {
    breakpoints: {
        SS: 320,
        Small: 780,
        Medium: 1200,
        Large: 1440
    }
})



/* vuex */

import vuex from 'vuex'
Vue.use(vuex)

import axios from 'axios'
import cookies from './assets/js/moudles/controlCookie'
let token = new cookies().getCookie('token')
window.axios = axios

// if(token){
//     axios.defaults.headers.common.Authorization = `seoo.demo.pnetwork.com.tw ${token}`
// }
console.log(axios.defaults)

// http request 拦截器
axios.interceptors.request.use(
    config => {
        if (token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers['Authorization'] = `seoo.demo.pnetwork.com.tw ${token}`
        }
        console.log(config)
        return config
    },
    err => {
        return Promise.reject(err)
    })

// http response 拦截器
// axios.interceptors.response.use(
//     response => {
//         return response
//     },
//     error => {
//         if (error.response) {
//             console.log('axios:' + error.response.status)
//             switch (error.response.status) {
//                 case 401:
//                     // 返回 401 清除token信息并跳转到登录页面
//                     store.commit('LOG_OUT');
//                     router.replace({
//                         path: 'login',
//                         query: { redirect: router.currentRoute.fullPath }
//                     });
//             }
//         }
//         return Promise.reject(error.response.data)   // 返回接口返回的错误信息
//     });


let vm = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})