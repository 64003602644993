import Vue from 'vue'
import * as types from '../mutations_type'

// states

export const state = {
    analysisData: [],
    competeData: []
}

export const mutations = {
    [types.ANALYSIS](state, url) {
        state.analysisData = [{
            label: 'SEO',
            score: '30',
            status: 'worse',
            detail: {}
        },
        {
            label: '易用性',
            score: '50',
            status: 'normal',
            detail: {}
        },
        {
            label: '相容性',
            score: '30',
            status: 'worse',
            detail: {}
        },
        {
            label: '技術',
            score: '80',
            status: 'good',
            detail: {}
        },
        {
            label: '社群',
            score: '30',
            status: 'worse',
            detail: {}
        },
        {
            label: '訪客',
            score: '40',
            status: 'worse',
            detail: {}
        },
        {
            label: '連結分析',
            score: '60',
            status: 'normal',
            detail: {}
        }
        ]
        console.log('anaysis ' + url);
    },
    [types.COMPETE](state, urls) {
        state.analysisData = [{
            label: 'SEO',
            score: '30',
            status: 'worse',
            detail: {}
        },
        {
            label: '易用性',
            score: '50',
            status: 'normal',
            detail: {}
        },
        {
            label: '相容性',
            score: '30',
            status: 'worse',
            detail: {}
        },
        {
            label: '技術',
            score: '80',
            status: 'good',
            detail: {}
        },
        {
            label: '社群',
            score: '30',
            status: 'worse',
            detail: {}
        },
        {
            label: '訪客',
            score: '40',
            status: 'worse',
            detail: {}
        },
        {
            label: '連結分析',
            score: '60',
            status: 'normal',
            detail: {}
        }
        ]
        state.competeData = state.analysisData
        console.log('compare ' + urls[0] + ' & ' + urls[1])
    }
}