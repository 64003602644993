<template>
    <div class="content">
        <hero>會員專區</hero>
        <div class="user section">
            <div class="section__content section__content--padding-tb">
                <div class="inner">
                    <div v-if="pageType==='forget'" class="user__edit user__edit--register">
                        <div class="user__upper text-center">
                            <h2 class="user__title block__title block__title--noline block__title--nopadding">忘記密碼</h2>
                            <p class="member__msg text-center">請輸入註冊信箱</p>
                        </div>
                        <div class="user__profile user__profile--register">
                            <div class="user__edit-form">
                                <form class="form">
                                    <ul class="form__list">
                                        <li class="form__item">
                                            <input type="email" class="form__input form__input--full" placeholder="" v-model="email">
                                            <p class="form__under"></p>
                                        </li>

                                    </ul>
                                    <p class="button-line button-line--align-center button-line--underform">
                                        <a @click="showBox = 'resetBox'" href="javascript:;" class="btn normal-btn">重新設定</a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div v-if="pageType==='reset'" class="user__edit user__edit--register">
                        <div class="user__upper text-center">
                            <h2 class="user__title block__title block__title--noline block__title--nopadding">重設密碼</h2>
                        </div>
                        <div class="user__profile user__profile--register">
                            <div class="user__edit-form">
                                <form class="form">
                                    <ul class="form__list">
                                        <li class="form__item">
                                            <label for="password" class="form__label">重設密碼</label>
                                            <input type="password" class="form__input" placeholder="">
                                            <p class="form__under">* 密碼長度至少為8個字元，並至少包含1個數字及大小寫英文字</p>
                                        </li>
                                        <li class="form__item">
                                                <label for="repassword" class="form__label">確認密碼</label>
                                                <input type="password" class="form__input" placeholder="">
                                                <p class="form__under"></p>
                                            </li>
                                    </ul>
                                    <p class="button-line button-line--align-center button-line--underform">
                                        <a  href="javascript:;" class="btn normal-btn">重新設定</a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <lightbox box-title="密碼重置信送出" :show-box="showBox" button-align="center" box-id="resetBox" @closebox="showBox = 'none'">
            <template slot="msg">
                <p class="text-center">密碼重置信已送出至您的註冊信箱，<br>請前往信箱進行密碼重置程序。</p>
            </template>
            <template slot="buttons">
                <a href="javascript:;" class="btn normal-btn normal-btn--40" @click="showBox = 'none'">確定</a>
            </template>
        </lightbox>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import Vue from 'vue'
    import footerAnalysis from '../components/footerAnalysis.vue'
    import lightbox from './../components/lightbox.vue'
    import hero from './../components/hero.vue'

    export default {
        data() {
            return {
                email: '',
                pageType: this.$route.meta.type,
                showBox: 'none'
            }
        },
        components: {
            hero,
            footerAnalysis,
            lightbox
        }
    }
</script>