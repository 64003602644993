<template>
        <div class="content">
            <hero>會員專區</hero>
            <div class="user section">
                <div class="section__content section__content--padding-tb">
                    <div class="inner">
                        <div v-if="!finished && !active" class="user__edit user__edit--register">
                            <div class="user__upper text-center">
                                <h2 class="user__title block__title block__title--noline block__title--nopadding">基本資料</h2>
                                <p class="member__msg text-center">還沒有會員嗎？請由下方加入我們吧</p>
                            </div>
                            <div class="user__profile user__profile--register">
                                <div class="user__edit-form">
                                    <form class="form">
                                        <ul class="form__list">
                                            <li class="form__item" :class="{'form__item--warning': errors.has('name')}">
                                                <label for="name" class="form__label">姓名</label>
                                                <input type="text" class="form__input" name="name" placeholder="" v-model="name" v-validate="'required'">
                                                <p class="form__under">{{ errors.first('name') }}</p>
                                            </li>
                                            <li class="form__item" :class="{'form__item--warning': errors.has('email')}">
                                                    <label for="email" class="form__label">Email</label>
                                                    <input type="email" class="form__input" placeholder="" name="email" v-model="email" v-validate="'required|email'">
                                                    <p class="form__under">{{ (errors.first('email'))? errors.first('email') : '* 將成為您日後的帳號'}}</p>
                                                </li>
                                            <li class="form__item" :class="{'form__item--warning': errors.has('password')}">
                                                <label for="password" class="form__label">會員密碼</label>
                                                <input type="password" class="form__input" placeholder="" name="password" v-model="password" v-validate="'required|password'" ref="password">
                                                <p class="form__under">{{ (errors.first('password'))? errors.first('password') : '* 密碼長度至少為8個字元，並至少包含1個數字及大小寫英文字'}}</p>
                                            </li>
                                            <li class="form__item" :class="{'form__item--warning': errors.has('repassword')}">
                                                    <label for="repassword" class="form__label">確認密碼</label>
                                                    <input type="password" class="form__input" placeholder="" name="repassword" v-model="repassword" v-validate="'required|confirmed:password'">
                                                    <p class="form__under">{{ errors.first('repassword') }}</p>
                                                </li>
                                        </ul>
                                        <p class="button-line button-line--align-center button-line--underform">
                                            <a @click="submit()" href="javascript:;" class="btn normal-btn">送出資料</a>
                                        </p>
                                        <p class="form__under form__under--margin-t form__under--no-space text-center">加入會員，代表您同意了SEOO的會員條款。<br>如有其他疑問請聯絡客服。</p>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <article v-else class="article text-center">
                            <template v-if="!active">
                            <h2 class="article__title">註冊信已寄出</h2>
                            <div class="article__content">請至信箱開啟註冊確認信以便啟用帳號。</div>
                            <div class="button-line button-line--align-center button-line--underform">
                                <router-link :to="{name:'index'}" class="btn normal-btn">回到首頁</router-link>
                            </div>
                            </template>
                            <template v-else>
                                <h2 class="article__title">{{ activeMsg.title }}</h2>
                                <div class="article__content">{{ activeMsg.msg }}</div>
                                <div class="button-line button-line--align-center button-line--underform">
                                    <router-link :to="{name:'index'}" class="btn normal-btn">回到首頁</router-link>
                                </div>
                            </template>
                        </article>
                    </div>
                </div>
            </div>
            <lightbox :box-title="regMsg.title" :show-box="showBox" button-align="center" box-id="regMsgBox" @closebox="showBox = 'none'">
                <template slot="msg">
                    <p class="text-center">{{ regMsg.msg }}</p>
                </template>
                <template slot="buttons">
                    <a href="javascript:;" class="btn normal-btn normal-btn--40" @click="showBox = 'none'">確定</a>
                </template>
            </lightbox>
            <footer-analysis v-if="finished"></footer-analysis>
        </div>
    </template>
    <script>
        import {mapGetters, mapActions} from 'vuex'
        import Vue from 'vue'
        import footerAnalysis from '../components/footerAnalysis.vue'
        import hero from './../components/hero.vue'
        import lightbox from './../components/lightbox.vue'

        export default {
            data(){
                return{
                    name: '',
                    email: '',
                    password: '',
                    repassword: '',
                    finished: false,
                    showBox: '',
                    regMsg: {
                        title: '',
                        msg: ''
                    },
                    activeMsg: {}
                }
            },
            components:{
                hero,
                footerAnalysis,
                lightbox
            },
            computed: {
                ...mapGetters({
                    getRegState: 'getRegState',
                    getActiveState: 'getActiveState'
                }),

            },
            created(){
                this.active = this.$route.meta.active
                let authCode = this.getUrlVars('auth_code')
                if((typeof authCode != 'undefined' || authCode != null) && this.active ){

                }else{

                }
                if(this.active) this.$store.dispatch('actionActiveAccount', authCode).then(()=>{
                    this.activeMsg = {
                        title: (this.getActiveState.success)? "啟用帳號成功" : "啟用帳號失敗",
                        msg: this.getActiveState.msg
                    }
                })
            },
            methods: {
                ...mapActions([
                    'actionRegister',
                    'actionActiveAccount'
                ]),
                submit(){
                    this.$validator.validateAll().then((result)=>{
                        //console.log(result)
                        if(result) this.$store.dispatch('actionRegister', {name: this.name, mail: this.email, password: this.password}).then(()=>{
                            console.log(this.getRegState)
                            if(this.getRegState.success){
                                this.finished = true
                            }else{
                                this.regMsg = {
                                    title: '註冊失敗',
                                    msg: this.getRegState.msg
                                }
                                this.showBox = 'regMsgBox'
                            }
                        })
                    })

                },
                getUrlVars(param) {
                    let vars = {};
                    let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
                        vars[key] = value;
                    });
                    return vars[param];
                }
            }
        }
    </script>