



function removeClass(selector, classname) {
    let els = document.querySelectorAll(selector + '.' + classname)
    if(els[0]) {
        els[0].classList.remove(classname)
        removeClass(selector, classname)
    }
}


//module.exports = Person;
//es6
export default removeClass;