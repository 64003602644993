<template>
    <div class="content">
        <hero>會員專區</hero>
        <div class="expense section">
            <div class="section__content section__content--padding-tb-less">
                <div class="inner">
                    <div class="crumb">
                        <a href="" class="crumb__text">會員專區</a>
                        <span href="" class="crumb__text">點數消費記錄</span>
                    </div>

                    <div class="expense__container">
                        <h2 class="text-center block__title block__title--noline block__title--nopadding">點數消費記錄</h2>
                        <div class="expense__block">
                            <div class="data-sheet data-sheet--multi data-sheet--expense">
                                <div class="flexbox data-sheet__row data-sheet__row--head mb-hide text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5">購買日</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5">啟用日</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5">到期日</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5">購買點數</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5">剩餘點數</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5">詳細內容</div>
                                </div>
                                <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row--odd text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">購買日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">啟用日</span>未啟用</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">到期日</span>-</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">購買點數</span>5點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">剩餘點數</span>2點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">詳細內容</span><a href="javascript:;" class="data-sheet__fold-btn" data-sub="sub0" @click="toggleSubSheet">+</a></div>
                                </div>

                                <div class="data-sheet data-sheet--sub data-sheet--fold" id="sub0">
                                    <div class="flexbox data-sheet__row data-sheet__row--subhead text-center mb-hide">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5">使用日</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left">檢測網址</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5">檢測報告下載</div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row--odd text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                </div>

                                <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">購買日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">啟用日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">到期日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">購買點數</span>5點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">剩餘點數</span>2點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">詳細內容</span><a href="javascript:;" class="data-sheet__fold-btn" data-sub="sub1" @click="toggleSubSheet">+</a></div>
                                </div>
                                <div class="data-sheet data-sheet--sub data-sheet--fold" id="sub1">
                                    <div class="flexbox data-sheet__row data-sheet__row--subhead text-center mb-hide">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5">使用日</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left">檢測網址</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5">檢測報告下載</div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row--odd text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                </div>

                                <div class="flexbox flexbox--column-atsmall data-sheet__row--odd data-sheet__row text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">購買日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">啟用日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">到期日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">購買點數</span>5點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">剩餘點數</span>2點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">詳細內容</span><a href="javascript:;" class="data-sheet__fold-btn" data-sub="sub1">+</a></div>
                                </div>
                                <div class="data-sheet data-sheet--sub data-sheet--fold">
                                    <div class="flexbox data-sheet__row data-sheet__row--subhead text-center mb-hide">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5">使用日</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left">檢測網址</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5">檢測報告下載</div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row--odd text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                </div>

                                <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">購買日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">啟用日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-left">到期日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">購買點數</span>5點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">剩餘點數</span>2點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--1-5"><span class="data-sheet__item-name text-left">詳細內容</span><a href="javascript:;" class="data-sheet__fold-btn" data-sub="sub1">+</a></div>
                                </div>
                                <div class="data-sheet data-sheet--sub data-sheet--fold">
                                    <div class="flexbox data-sheet__row data-sheet__row--subhead text-center mb-hide">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5">使用日</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left">檢測網址</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5">檢測報告下載</div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row--odd text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                    <div class="flexbox flexbox--column-atsmall data-sheet__row text-center">
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">使用日</span>2018/01/01 00:00</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--7 text-left text-center--atsmall"><span class="data-sheet__item-name text-center">檢測網址</span>www.nowilldesign.com</div>
                                        <div class="data-sheet__col flexbox__col flexbox__col--2-5"><span class="data-sheet__item-name text-center">報告下載</span><a href="" class="inline-link">下載</a></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import Vue from 'vue'
    import hero from './../components/hero.vue'
    import { slideDown, slideUp, slideStop, isVisible } from 'slide-anim';
    import scrollTo from 'animated-scroll-to'

    export default {
        data(){
            return{
            }
        },
        components: {
            hero
        },
        methods:{
            toggleSubSheet(e){
                let toggleID = e.currentTarget.getAttribute('data-sub')
                let toggle = document.querySelector("#"+toggleID)
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    slideUp(toggle)
                    e.currentTarget.textContent = '+'
                }else{
                    toggle.classList.add('unfold')
                    slideDown(toggle)
                    e.currentTarget.textContent = '-'
                }
                // setTimeout(()=>{
                //     scrollTo(toggle, {
                //         offset: -60
                //     })
                // }, 100)
            }
        },
    }
</script>