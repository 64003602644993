import Vue from 'vue';
import {state, mutations} from './mutations.js';
import * as types from '../mutations_type.js';

export const getters = {
    getAnalysis(state) {
        return state.analysisData
    },
    getCompete(state) {
        return state.competeData
    }
}

export const actions = {
    actionAnalysis({commit}, url){

        commit(types.ANALYSIS, url)
    },
    actionCompete({commit}, urls){
        commit (types.COMPETE, urls)
    }
}