<template>
    <div class="radar">
        <canvas :class="radarName"></canvas>
    </div>
</template>
<script>
    import chart from './../../node_modules/chart.js/dist/Chart.js'
    Chart.defaults.global.responsive = true
    let chartColors = {
        red: 'rgb(255, 99, 132)',
        orange: 'rgb(255, 159, 64)',
        yellow: 'rgb(255, 205, 86)',
        green: 'rgb(75, 192, 192)',
        blue: 'rgb(54, 162, 235)',
        purple: 'rgb(153, 102, 255)',
        grey: 'rgb(201, 203, 207)'
    };
    var color = Chart.helpers.color;
    let labelColors = {
        good: '#B8E986',
        normal: '#F5A623',
        worse: 'rgba(208, 2, 27, .5)'
    }
    export default {
        props:{
            radarName: String,
            radarData: Array
        },
        computed: {
        },
        mounted(){
            console.log(this.radarData)
            let ctx = document.querySelector('.'+this.radarName)
            ctx.setAttribute('width', ctx.parentElement.offsetWidth)
            ctx.setAttribute('height', ctx.parentElement.offsetHeight)
            let radarScores = []
            let radarLabels = []
            let radarColors = []
            this.radarData.forEach(element => {
                radarScores.push(parseInt(element.score))
                radarLabels.push(element.label)
                radarColors.push(labelColors[element.status])
            });
            let chartData = {
                datasets: [{
                    data: radarScores,
                    backgroundColor: [color(chartColors.red).alpha(0.5).rgbString(),
                    color(chartColors.orange).alpha(0.5).rgbString(),
                    color(chartColors.yellow).alpha(0.5).rgbString(),
                    color(chartColors.green).alpha(0.5).rgbString(),
                    color(chartColors.blue).alpha(0.5).rgbString(),
                    color(chartColors.purple).alpha(0.5).rgbString(),
                    color(chartColors.grey).alpha(0.5).rgbString(),]
                }],
                labels: radarLabels
            }
            let radarChart = new Chart(ctx, {
                data: chartData,
                type: 'polarArea',
                options: {
                    layout:{
                        padding: {
                            top: 10,
                            bottom: 10
                        }
                    },
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    scale: {
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 10,
                            fontSize: 18,
                        },
                        gridLines: {
                            lineWidth: 2,
                        },
                        reverse: false
                    }
                }
            })
            window.addEventListener('resize', function(){
                console.log(window.innerWidth)
                radarChart.options.scale.ticks.fontSize = (window.innerWidth <=640) ? 10 : 18
                //radarChart.options.scale.gridLines = (window.innerWidth <=640) ? 1 : 2
                radarChart.update()
            })
            window.dispatchEvent(new Event('resize'))
        },
        methods:{
        }
    }

</script>