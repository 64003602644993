<template>
    <div class="content start">
        <visual :competition="startType"></visual>
    </div>
</template>
<script>
    import visual from './../components/visual.vue'

    export default {
        components:{
            visual
        },
        computed:{
            startType(){
                return this.$route.meta.startType === 'competition'
            }
        },
    }
</script>