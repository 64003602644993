<template>
        <div class="content">
            <hero>會員專區</hero>
            <div class="user section">
                <div class="section__content section__content--padding-tb-less">
                    <div class="inner">
                        <div class="crumb">
                            <a href="" class="crumb__text">會員專區</a>
                            <span href="" class="crumb__text">會員資料</span>
                        </div>
                        <div class="user__edit">
                            <div class="user__upper text-center">
                                <h2 class="user__title block__title block__title--noline block__title--nopadding">會員資料</h2>
                                <p class="user__warning"><span class="user__warning-item">確認密碼</span>欄位內容不正確，請重新填寫</p>
                            </div>
                            <div class="user__profile">
                                <div class="user__avatar text-center">
                                    <input class="uploadphoto" id="upload" type="file" accept="image/*" style="display:none;" @change="readURL()">
                                    <div class="user__avatar-photo" :style="{ backgroundImage: `url(${require('./../assets/images/dummy.png')})` }"></div>
                                    <a href="javascript:;" class="small-btn btn small-btn--reverse user__upload-btn small-btn--s" @click="upload()">檔案上傳</a>
                                    <p class="user__hint">* 檔案需小於1MB，檔案規格僅限 *.jpg、*.png</p>
                                </div>

                                <div class="user__edit-form">
                                    <form class="form">
                                        <ul class="form__list">
                                            <li class="form__item">
                                                <label for="name" class="form__label">姓名</label>
                                                <input type="text" class="form__input" placeholder="" v-model="name">
                                                <p class="form__under"></p>
                                            </li>
                                            <li class="form__item">
                                                    <label for="email" class="form__label">Email</label>
                                                    <span class="form__fixed-data">{{ email }}</span>
                                                    <p class="form__under"></p>
                                                </li>
                                            <li class="form__item">
                                                <label for="password" class="form__label">會員密碼</label>
                                                <input type="password" class="form__input" placeholder="如不需修改可直接跳過">
                                                <p class="form__under">* 密碼長度至少為8個字元，並至少包含1個數字及大小寫英文字</p>
                                            </li>
                                            <li class="form__item form__item--warning">
                                                    <label for="repassword" class="form__label">確認密碼</label>
                                                    <input type="password" class="form__input" placeholder="如不需修改可直接跳過">
                                                    <p class="form__under">* 密碼長度至少為8個字元，並至少包含1個數字及大小寫英文字</p>
                                                </li>
                                        </ul>
                                        <p class="button-line button-line--align-center button-line--underform">
                                            <a href="" class="btn normal-btn">更新資料</a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <script>
        import {mapGetters, mapActions} from 'vuex'
        import Vue from 'vue'
        import hero from './../components/hero.vue'

        export default {
            data(){
                return{
                    name: '王大明',
                    email: 'wdm@gmail.com',
                }
            },
            components:{
                hero
            },
            computed:{
                ...mapGetters({
                    uploadState: 'getUploadState'
                })
            },
            methods:{
                ...mapActions([
                    'actionUploadPhoto'
                ]),
                upload(){
                    document.getElementById('upload').dispatchEvent(new MouseEvent('click'))

                },
                readURL() {
                    //console.log(input)
                    let input = document.getElementById('upload')
                    console.log(input.files)
                    //let store = this.$store
                    if (input.files && input.files[0]) {
                        let file = input.files[0];
                        let reader = new FileReader();
                        let imgurl;
                        reader.onload = (e) => {
                            console.log(input.files[0])
                            this.$store.dispatch('actionUploadPhoto', input.files[0]).then(()=>{
                                console.log(this.uploadState)
                            })
                        }
                        reader.readAsBinaryString(input.files[0]);

                    }
                }
            }
        }
    </script>