<template>
    <div class="content" id="top">
        <hero>{{ pageTitle }}</hero>
        <side-nav></side-nav>
        <div class="result">
            <!-- overview -->
            <div class="section section--nopadding-atsmall">
                <div class="section__content section__content--padding-tb" :class="{'section__content--nopadding-t-atsmall': type !== 'last' }">
                    <div v-if="type==='last'" class="section__head section__head--nopadding-t-atsmall text-center flexbox flexbox--end">
                        <div class="flexbox__col flexbox__col--10">
                            <h3 class="section__title section__title--noline">nowilldesign.com</h3>
                        </div>
                    </div>
                    <div class="inner inner--nopadding-atsmall flexbox flexbox--end">
                        <!-- result 1 (last or analysis)-->
                        <!-- compare last -->
                        <div class="flexbox__col flexbox__col--padding-lr flexbox__col--5 flexbox__col--6-atsmall flexbox__col--even-atsmall">
                            <div v-if="type==='last'">
                                <h4 class="block__title block__title--noline block__title--nopadding text-center block__title--padding-t-atsmall block__title--smaller-atsmall">前次</h4>
                                <div class="result__date result__date--select text-center">
                                    <label for="" class="result__select-label mb-hide">檢測紀錄</label>
                                    <span class="result__select-wrap">
                                        <i class="far fa-calendar-alt"></i>
                                        <div class="custom-select">
                                            <input class="custom-select__input" type="text" v-model="date">
                                            <select class="custom-select__select">
                                                <option :value="date">{{date}}</option>
                                                <option :value="date">{{date}}</option>
                                            </select>
                                        </div>
                                        <i class="fas fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>
                            <!-- compete with another -->
                            <div v-else class="result__competition-head text-center">
                                <h4 class="section__title section__title--noline text-ellipsis">nowilldesign.com</h4>
                                <div class="result__score-block result__score-block--b result__score-block--smaller-atsmall result__score-block--margin-tb">
                                    <p class="result__score-label result__score-label--smaller-atsmall"><span class="icon"><img src="./../assets/images/icon-seo-3.svg" alt="SEOO"></span>評分</p>
                                    <p class="result__score result__score--smaller-atsmall">
                                        <span class="get">60</span><span class="max">/100</span>
                                    </p>
                                </div>
                            </div>
                            <radar radar-name="radar--analysis" :radar-data="analysisData"></radar>
                            <div class="radar__bars radar__bars--noborder-atsmall">
                                <p v-if="type === 'another'" class="radar__bars-title radar__bars-title--relative-atsmall">SEO現況</p>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--good">
                                        <i class="fas fa-check fa-fw"></i>好東西<span class="radar__percent">5%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--good" data-percent="30"><span class="bar"></span></span>
                                </div>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--normal">
                                        <i class="fas fa-exclamation fa-fw"></i>待改進<span class="radar__percent">25%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--normal" data-percent="70"><span class="bar"></span></span>
                                </div>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--worse">
                                        <i class="fas fa-times fa-fw"></i>有問題<span class="radar__percent">99%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--worse" data-percent="50"><span class="bar"></span></span>
                                </div>
                            </div>
                        </div>
                        <!-- result 2 (this time or compete)-->
                        <div class="flexbox__col flexbox__col--padding-lr flexbox__col--5 flexbox__col--6-atsmall">
                            <!-- compare last -->
                            <div v-if="type==='last'">
                                <p class="button-line button-line--padding-t-less-atsmall button-line--align-center"><a class="btn small-btn small-btn--smaller-atsmall" href="javascript:;">立即產出現況報告<span class="sub">(1點)</span></a></p>
                                <div class="result__date result__date--select text-center">
                                    <label for="" class="result__select-label mb-hide">檢測紀錄</label>
                                    <span class="result__select-wrap">
                                        <i class="far fa-calendar-alt"></i>
                                        <div class="custom-select">
                                            <input class="custom-select__input" type="text" v-model="date">
                                            <select class="custom-select__select">
                                                <option :value="date">{{date}}</option>
                                                <option :value="date">{{date}}</option>
                                            </select>
                                        </div>
                                        <i class="fas fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>
                            <!-- compete with another -->
                            <div v-else class="result__competition-head text-center">
                                <h4 class="section__title section__title--noline text-ellipsis">pnetwork.com</h4>
                                <div class="result__score-block result__score-block--a result__score-block--smaller-atsmall result__score-block--margin-tb">
                                    <p class="result__score-label result__score-label--smaller-atsmall"><span class="icon"><img src="./../assets/images/icon-seo-3.svg" alt="SEOO"></span>評分</p>
                                    <p class="result__score result__score--smaller-atsmall">
                                        <span class="get">80</span><span class="max">/100</span>
                                    </p>
                                </div>
                            </div>
                            <radar radar-name="radar--compete" :radar-data="competeData"></radar>
                            <div class="radar__bars radar__bars--noborder-atsmall">
                                <p v-if="type === 'another'" class="radar__bars-title radar__bars-title--relative-atsmall">SEO現況</p>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--good">
                                        <i class="fas fa-check fa-fw"></i>好東西<span class="radar__percent">5%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--good" data-percent="30"><span class="bar"></span></span>
                                </div>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--normal">
                                        <i class="fas fa-exclamation fa-fw"></i>待改進<span class="radar__percent">25%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--normal" data-percent="70"><span class="bar"></span></span>
                                </div>
                                <div class="radar__bar">
                                    <p class="radar__label radar__label--worse">
                                        <i class="fas fa-times fa-fw"></i>有問題<span class="radar__percent">99%</span>
                                    </p>
                                    <span class="radar__bar-body radar__bar-body--worse" data-percent="50"><span class="bar"></span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- seo -->
            <div class="section section--toggle section--padding-b-less" id="seo">
                <h2 class="section__title section__title--toggle section__title--noline" @click="toggleSection"><span class="section__title-text">SEO<span class="section__title-bubble">她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了……</span></span></h2>
                <div class="section__content section__content--padding-tb section__content--toggle unfold">
                    <div class="inner">
                        <div class="data-table data-table--competition">
                            <div class="data-table__row data-table__row--head flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2"><span class="name-label">項目</span></div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium text-center">評分敘述</div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium text-center">評分敘述</div>
                            </div>

                            <div class="data-table__help data-table__help--sitetitle">
                                <p class="data-table__content data-table__content--help">
                                        她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了然靜成，中天業…… 她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……
                                </p>
                            </div>

                            <div class="data-table__row flexbox flexbox--nomargin-atsmall flexbox--wrap-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon" @click="toggleHelp" data-help="data-table__help--sitetitle"><i class="far fa-question-circle"></i></span>
                                        標題名稱
                                        <span class="data-table__state"><i class="fas fa-check"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium flexbox__col--6-atsmall">
                                        <div class="data-table__col-inner">
                                            <p class="data-table__content">品禾全端網路工作室 | 專業RWD跨平台網站與購物車建置專家</p>
                                            <p class="data-table__sub-content">Length: 30 character(s)</p>
                                        </div>
                                    </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium flexbox__col--6-atsmall">
                                    <div class="data-table__col-inner">
                                        <p class="data-table__content">品禾全端網路工作室 | 專業RWD跨平台網站與購物車建置專家</p>
                                        <p class="data-table__sub-content">Length: 30 character(s)</p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--nomargin-atsmall flexbox--wrap-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2 flexbox__col--2-atmedium">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Meta敘述
                                        <span class="data-table__state"><i class="fas fa-times"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium flexbox__col--6-atsmall">
                                        <div class="data-table__col-inner">
                                            <p class="data-table__content">Pinnacle Network Studio 品禾全端稟著提供客戶最專業的服務之念頭，多年來跨足了多項網站相關領域，舉凡架構、設計、企劃、PHP程式、社群、行銷、使用者研究等等，皆有3~5年不等的實戰經驗，是個對網路充滿熱情與專注的專業設計師</p>
                                            <p class="data-table__sub-content">Length: 122 character(s)</p>
                                        </div>
                                    </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium flexbox__col--6-atsmall">
                                    <div class="data-table__col-inner">
                                        <p class="data-table__content">Pinnacle Network Studio 品禾全端稟著提供客戶最專業的服務之念頭，多年來跨足了多項網站相關領域，舉凡架構、設計、企劃、PHP程式、社群、行銷、使用者研究等等，皆有3~5年不等的實戰經驗，是個對網路充滿熱情與專注的專業設計師</p>
                                        <p class="data-table__sub-content">Length: 122 character(s)</p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--nomargin-atsmall flexbox--wrap-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Meta關鍵字
                                        <span class="data-table__state"><i class="fas fa-exclamation"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium flexbox__col--6-atsmall">
                                    <div class="data-table__col-inner">
                                        <p class="data-table__content">品禾全端,響應式網站設計,rwd網頁設計,台北rwd網站設計,台北響應式網站設計,主機代管,PHP程式設計,網站行銷</p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium flexbox__col--6-atsmall">
                                    <div class="data-table__col-inner">
                                        <p class="data-table__content">品禾全端,響應式網站設計,rwd網頁設計,台北rwd網站設計,台北響應式網站設計,主機代管,PHP程式設計,網站行銷</p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--nomargin-atsmall flexbox--wrap-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        標題
                                        <span class="data-table__state"><i class="fas fa-exclamation"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium data-table__col--block-atsmall ">
                                        <div class="data-table__col-inner data-table__col-inner--block-atsmall">
                                            <table class="data-table__table data-table__table--border-between text-center">
                                                <tr>
                                                    <td>&lt;H1&gt;</td>
                                                    <td>&lt;H2&gt;</td>
                                                    <td>&lt;H3&gt;</td>
                                                    <td>&lt;H4&gt;</td>
                                                    <td>&lt;H5&gt;</td>
                                                    <td>&lt;H6&gt;</td>
                                                </tr>
                                                <tr>
                                                    <td>0</td>
                                                    <td>5</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                    <td>2</td>
                                                    <td>0</td>
                                                </tr>
                                            </table>
                                            <ul class="data-table__tb-list data-table__tb-list--odd data-table__tb-list--toggle">
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                                <li class="data-table__tb-item">
                                                    &lt;H1&gt;Title1&lt;/H1&gt;
                                                </li>
                                            </ul>
                                            <p class="data-table__content text-center">
                                                <more-link></more-link>
                                            </p>
                                        </div>
                                    </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium data-table__col--block-atsmall ">
                                    <div class="data-table__col-inner data-table__col-inner--block-atsmall">
                                        <table class="data-table__table data-table__table--border-between text-center">
                                            <tr>
                                                <td>&lt;H1&gt;</td>
                                                <td>&lt;H2&gt;</td>
                                                <td>&lt;H3&gt;</td>
                                                <td>&lt;H4&gt;</td>
                                                <td>&lt;H5&gt;</td>
                                                <td>&lt;H6&gt;</td>
                                            </tr>
                                            <tr>
                                                <td>0</td>
                                                <td>5</td>
                                                <td>4</td>
                                                <td>0</td>
                                                <td>2</td>
                                                <td>0</td>
                                            </tr>
                                        </table>
                                        <ul class="data-table__tb-list data-table__tb-list--odd data-table__tb-list--toggle">
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                        </ul>
                                        <p class="data-table__content text-center">
                                            <more-link></more-link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--nomargin-atsmall flexbox--wrap-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Google預覽
                                        <span class="data-table__state"><i class="fas fa-check"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium flexbox__col--12-atsmall">
                                    <div class="data-table__col-inner">
                                    <p class="data-table__content text-center--atsmall">登入以獲取更多資料<a href="javascript:;" class="btn btn--unlock small-btn btn--inline"><i class="fas fa-unlock"></i> 解鎖此項目</a></p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium mb-hide">
                                    <div class="data-table__col-inner">
                                    <p class="data-table__content text-center--atsmall">登入以獲取更多資料<a href="javascript:;" class="btn btn--unlock small-btn btn--inline"><i class="fas fa-unlock"></i> 解鎖此項目</a></p>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table__row flexbox flexbox--nomargin-atsmall flexbox--wrap-atsmall">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                       Alt Attribute
                                        <span class="data-table__state"><i class="fas fa-check"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium flexbox__col--12-atsmall">
                                    <div class="data-table__col-inner">
                                    <p class="data-table__content text-center--atsmall">登入以獲取更多資料<a href="javascript:;" class="btn btn--unlock small-btn btn--inline"><i class="fas fa-unlock"></i> 解鎖此項目</a></p>
                                    </div>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--5 flexbox__col--5-atmedium mb-hide">
                                    <div class="data-table__col-inner">
                                    <p class="data-table__content text-center--atsmall">登入以獲取更多資料<a href="javascript:;" class="btn btn--unlock small-btn btn--inline"><i class="fas fa-unlock"></i> 解鎖此項目</a></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 易用性 -->
            <div class="section section--toggle" id="usability">
                <h2 class="section__title section__title--toggle section__title--noline" @click="toggleSection">易用性</h2>
                <div class="section__content section__content--padding-tb section__content--toggle">
                    <div class="inner">
                        <div class="data-table">
                            <div class="data-table__row data-table__row--head flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2"><span class="name-label">項目</span></div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium text-center">影響度</div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium text-center">難易度</div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">評分敘述</div>
                            </div>

                            <div class="data-table__row flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        標題名稱
                                        <span class="data-table__state"><i class="fas fa-check"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light"></span>
                                        <span class="data-table__light data-table__light"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__content">品禾全端網路工作室 | 專業RWD跨平台網站與購物車建置專家</p>
                                    <p class="data-table__sub-content">Length: 30 character(s)</p>
                                </div>
                            </div>

                            <div class="data-table__row flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2 flexbox__col--2-atmedium">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Meta敘述
                                        <span class="data-table__state"><i class="fas fa-times"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light"></span>
                                        <span class="data-table__light data-table__light"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__content">Pinnacle Network Studio 品禾全端稟著提供客戶最專業的服務之念頭，多年來跨足了多項網站相關領域，舉凡架構、設計、企劃、PHP程式、社群、行銷、使用者研究等等，皆有3~5年不等的實戰經驗，是個對網路充滿熱情與專注的專業設計師</p>
                                    <p class="data-table__sub-content">Length: 122 character(s)</p>
                                </div>
                            </div>

                            <div class="data-table__row flexbox">
                                <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                    <p class="data-table__label">
                                        <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                        Meta關鍵字
                                        <span class="data-table__state"><i class="fas fa-exclamation"></i></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light--on"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                    <p class="data-table__lights">
                                        <span class="data-table__light data-table__light--on"></span>
                                        <span class="data-table__light data-table__light"></span>
                                        <span class="data-table__light data-table__light"></span>
                                    </p>
                                </div>
                                <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                    <p class="data-table__content">品禾全端,響應式網站設計,rwd網頁設計,台北rwd網站設計,台北響應式網站設計,主機代管,PHP程式設計,網站行銷</p>
                                </div>
                            </div>

                            <div class="data-table__row flexbox">
                                    <div class="data-table__col data-table__col--item-name flexbox__col flexbox__col--2">
                                        <p class="data-table__label">
                                            <span class="data-table__help-icon"><i class="far fa-question-circle"></i></span>
                                            Meta關鍵字
                                            <span class="data-table__state"><i class="fas fa-exclamation"></i></span>
                                        </p>
                                    </div>
                                    <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light--on"></span>
                                        </p>
                                    </div>
                                    <div class="data-table__col flexbox__col flexbox__col--1-5 flexbox__col--2-atmedium">
                                        <p class="data-table__lights">
                                            <span class="data-table__light data-table__light--on"></span>
                                            <span class="data-table__light data-table__light"></span>
                                            <span class="data-table__light data-table__light"></span>
                                        </p>
                                    </div>
                                    <div class="data-table__col flexbox__col flexbox__col--7 flexbox__col--6-atmedium">
                                        <table class="data-table__table data-table__table--border-between text-center">
                                            <tr>
                                                <td>&lt;H1&gt;</td>
                                                <td>&lt;H2&gt;</td>
                                                <td>&lt;H3&gt;</td>
                                                <td>&lt;H4&gt;</td>
                                                <td>&lt;H5&gt;</td>
                                                <td>&lt;H6&gt;</td>
                                            </tr>
                                            <tr>
                                                <td>0</td>
                                                <td>5</td>
                                                <td>4</td>
                                                <td>0</td>
                                                <td>2</td>
                                                <td>0</td>
                                            </tr>
                                        </table>
                                        <ul class="data-table__tb-list data-table__tb-list--odd data-table__tb-list--toggle">
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                            <li class="data-table__tb-item">
                                                &lt;H1&gt;Title1&lt;/H1&gt;
                                            </li>
                                        </ul>
                                        <p class="data-table__content text-center">
                                            <more-link></more-link>
                                        </p>
                                    </div>
                                </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-analysis></footer-analysis>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex'
    import Vue from 'vue'
    import hero from './../components/hero.vue'
    import radar from './../components/radar.vue'
    import footerAnalysis from '../components/footerAnalysis.vue'
    import sideNav from './../components/sidenav.vue'
    import moment from 'moment'
    import { slideDown, slideUp, slideStop, isVisible } from 'slide-anim';
    import scrollTo from 'animated-scroll-to'


    const moreLink = Vue.component('more-link', {
        data(){
            return{
                status: 'more'
            }
        },
        computed:{
            isMore(){
                return status == 'more'
            }
        },
        template: '<a href="javascript:;" class="inline-link inline-link--more" @click="toggleList">{{ status=="more" ? "看更多":"收回" }} <i class="fas" :class="{'+"'fa-plus-circle': status=='more','fa-minus-circle': status=='less'"+'}"></i></a>',
        //delimiters: ['${', '}'],
        methods:{
            toggleList(e){
                let toggle = e.currentTarget.parentNode.previousElementSibling
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    this.status = 'more'
                }else{
                    toggle.classList.add('unfold')
                    this.status = 'less'
                }
                console.log(this)
                setTimeout(()=>{
                    scrollTo(toggle, {
                        offset: -60
                    })
                }, 100)
            },
        }
    })

    let timeFormat = 'YYYY-MM-DD hh:mm'

    export default {
        data(){
            return {
                date: moment().format(timeFormat),
                type: this.$route.meta.competeTarget
            }
        },
        computed: {
            ...mapGetters({
                analysisData: 'getAnalysis',
                competeData: 'getCompete'
            }),
            pageTitle(){
                return (this.type === 'last') ? '前次比較':'雙網分析'
            },
        },
        components:{
            hero,
            radar,
            moreLink,
            sideNav,
            footerAnalysis
        },
        mounted(){
            document.querySelectorAll('.radar__bar-body .bar').forEach(function(item, key){
                item.style.width = item.parentNode.getAttribute('data-percent') + '%'
            })
            document.querySelectorAll('.section__content--toggle').forEach(function(item, key){
                if(!item.classList.contains('unfold')) item.style.display = 'none'
            })

        },
        methods:{
            renewDate(){
                console.log(this.date)
            },
            toggleSection(e){
                let toggle = e.currentTarget.nextElementSibling
                let _this = e.currentTarget
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    slideUp(toggle)
                }else{
                    toggle.classList.add('unfold')
                    slideDown(toggle)
                }
                setTimeout(()=>{
                    scrollTo(_this, {
                        offset: -60
                    })
                }, 100)
            },
            toggleHelp(e){
                let toggle = document.querySelector('.'+e.currentTarget.getAttribute('data-help'))
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    slideUp(toggle)
                }else{
                    toggle.classList.add('unfold')
                    slideDown(toggle)
                }
            }
        },
        watch:{
            analysisData: ()=>{
                scrollTo(0)
            }
        },
    }
</script>