<template>
    <div class="credit-card__outter" :class="{'swiper-container--credit-cards':type==='pay'}">
        <div :class="{'swiper-wrapper':type==='pay'}">
            <div class="credit-card credit-card--now" :class="{'swiper-slide':type==='pay'}">
                <div class="credit-card__wrap">
                    <div class="credit-card__card">
                        <div class="credit-card__front">
                            <img class="credit-card__body" src="./../assets/images/card-sample.png" alt="">
                            <p class="credit-card__sn"><span>{{ cards[0].cardNo[0] }}</span>&nbsp;<span>{{ cards[0].cardNo[1] }}</span>&nbsp;<span>{{ cards[0].cardNo[2] }}</span>&nbsp;<span>{{ cards[0].cardNo[3] }}</span></p>
                            <p class="credit-card__expire">{{ cards[0].expire }}</p>
                        </div>
                        <div class="credit-card__back">
                            <img class="credit-card__body" src="./../assets/images/card-sample-back.png" alt="">
                            <p class="credit-card__cvv">{{ cards[0].cvv }}</p>
                        </div>
                    </div>
                </div>

                <br>
                <form class="form">
                    <ul class="form__list">
                        <li class="form__item form__item--horizen-at-small">
                            <label for="" class="form__label form__label--large">信用卡號碼</label>
                            <input v-model="cards[0].cardNo[0]" type="text" id="card-number" class="form__input form__input--cardno" maxlength="4" placeholder="0000">
                            <input v-model="cards[0].cardNo[1]" type="text" id="card-number-1" class="form__input form__input--cardno" maxlength="4" placeholder="0000">
                            <input v-model="cards[0].cardNo[2]" type="text" id="card-number-2" class="form__input form__input--cardno" maxlength="4" placeholder="0000">
                            <input v-model="cards[0].cardNo[3]" type="text" id="card-number-3" class="form__input form__input--cardno" maxlength="4" placeholder="0000">
                        </li>
                        <li class="form__item">
                            <label for="" class="form__label form__label--large">有效日期</label>
                            <input v-model="cards[0].expire" type="text" id="cardExpire" class="form__input credit-card__expire-input" placeholder="MM/YY" maxlength="5">
                            <p v-if="type==='box'" class="form__under form__under--space-large">* 付款時需填寫卡片背面後3碼</p>
                        </li>
                        <li class="form__item" v-if="type==='pay'">
                            <label for="" class="form__label form__label--large">安全碼</label>
                            <input v-model="cards[0].cvv" type="text" id="cardCvv" class="form__input credit-card__cvv-input" placeholder="000" maxlength="3">
                        </li>
                    </ul>
                </form>
            </div>
            <div v-if="type==='pay'" class="credit-card" :class="{'swiper-slide':type==='pay'}">
                <div class="credit-card__wrap">
                    <div class="credit-card__card">
                        <div class="credit-card__front">
                            <img class="credit-card__body" src="./../assets/images/card-sample.png" alt="">
                            <p class="credit-card__sn"><span>{{ cards[1].cardNo[0] }}</span>&nbsp;<span>{{ cards[1].cardNo[1] }}</span>&nbsp;<span>{{ cards[1].cardNo[2] }}</span>&nbsp;<span>{{ cards[1].cardNo[3] }}</span></p>
                            <p class="credit-card__expire">{{ cards[1].expire }}</p>
                        </div>
                        <div class="credit-card__back">
                            <img class="credit-card__body" src="./../assets/images/card-sample-back.png" alt="">
                            <p class="credit-card__cvv">{{ cards[1].cvv }}</p>
                        </div>
                    </div>
                </div>

                <br>
                <form class="form">
                    <ul class="form__list">
                        <li class="form__item form__item--horizen-at-small">
                            <label for="" class="form__label form__label--large">信用卡號碼</label>
                            <input v-model="cards[1].cardNo[0]" type="text" class="form__input form__input--cardno" maxlength="4" placeholder="0000">
                            <input v-model="cards[1].cardNo[1]" type="text" class="form__input form__input--cardno" maxlength="4" placeholder="0000">
                            <input v-model="cards[1].cardNo[2]" type="text" class="form__input form__input--cardno" maxlength="4" placeholder="0000">
                            <input v-model="cards[1].cardNo[3]" type="text" class="form__input form__input--cardno" maxlength="4" placeholder="0000">
                        </li>
                        <li class="form__item">
                            <label for="" class="form__label form__label--large">有效日期</label>
                            <input v-model="cards[1].expire" type="text" id="cardExpire" class="form__input credit-card__expire-input" placeholder="MM/YY" maxlength="5">
                            <p v-if="type==='box'" class="form__under form__under--space-large">* 付款時需填寫卡片背面後3碼</p>
                        </li>
                        <li class="form__item" v-if="type==='pay'">
                            <label for="" class="form__label form__label--large">安全碼</label>
                            <input v-model="cards[1].cvv" type="text" id="cardCvv" class="form__input credit-card__cvv-input" placeholder="000" maxlength="3">
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        <div v-if="type==='pay'">
            <a class="swiper-button-prev credit-card__prev-btn" href="javascript:;"><i class="fas fa-arrow-circle-left"></i></a>
            <a class="swiper-button-next credit-card__next-btn" href="javascript:;"><i class="fas fa-arrow-circle-right"></i></a></div>
    </div>

</template>
<script>
    import Swiper from 'swiper'
    import removeClass from './../assets/js/moudles/removeClass'
    export default{
        props:{
            type: String
        },
        data(){
            return {
                cards:[
                    {
                        cardNo:['', '', '', ''],
                        expire:'',
                        default: true
                    }
                ]
            }
        },
        created(){
            if(this.type==='pay'){
                this.cards = [
                    {
                        cardNo:['1111', '2222', '3333', '4444'],
                        expire:'12/21',
                        cvv: '',
                        default: true
                    },
                    {
                        cardNo:['2222', '2222', '3333', '4444'],
                        expire:'12/22',
                        cvv: '',
                        default: false
                    },
                    {
                        cardNo:['3333', '2222', '3333', '4444'],
                        expire:'12/23',
                        cvv: '',
                        default: false
                    },
                ]
            }
        },

        mounted(){
            if(this.type==='pay'){


                let mySwiper = new Swiper ('.swiper-container--credit-cards', {
                    // Optional parameters
                    loop: false,
                    slidesPerView: '1',
                    centeredSlides: 'true',
                    spaceBetween: 40,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                });
                document.querySelectorAll('.credit-card__cvv-input').forEach(function(el, key){
                    el.addEventListener('focus', function(){
                        document.querySelector('.swiper-slide-active .credit-card__wrap').classList.add('back')
                    })
                    el.addEventListener('blur', function(){
                        removeClass('.credit-card__wrap')
                    })
                })
            }else{

            }
            document.querySelectorAll('.form__input--cardno').forEach(function(el, key){
                el.addEventListener('keyup', checkCardNo)
                el.addEventListener('change', checkCardNo)
            })
            document.querySelectorAll('.credit-card__expire-input').forEach(function(el, key){
                el.addEventListener('keyup', checkExpireDate)
                el.addEventListener('change', checkExpireDate)
            })



        }
    }
    const checkCardNo = function(event){
        /*document.querySelectorAll('.credit-card--now .credit-card__sn span').forEach(function(el, key){
            el.innerHTML = document.querySelectorAll('.credit-card--now .form__input--cardno')[key].value
        })*/
        if(event.currentTarget.value.length>=4 && event.currentTarget.nextElementSibling){
            event.currentTarget.nextElementSibling.focus()
        }
    }
    const checkExpireDate = function(event){
        var el = event.currentTarget
        //document.querySelector('.credit-card--now .credit-card__expire').innerHTML = el.value
        if(el.value.length==2 && event.keyCode != 8){
            el.value += "/"
        }
    }

</script>