//import Vue from 'vue'
import {state, mutations} from './mutations.js'
import * as types from '../mutations_type.js'
//import axios from 'axios'
import Qs from 'qs'
import cookies from './../../assets/js/moudles/controlCookie'
let token = new cookies().getCookie('token')
//axios.defaults.headers.post['Content-Type']= 'application/json'

// const service = axios.create({
//     timeout: 5000, // 请求的超时时间
//     //设置默认请求头，使post请求发送的是formdata格式数据
//     headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//     },
//     withCredentials: true, // 允许携带cookie
//     transformRequest: [function(data) {
//         let newData = ''
//         for (let k in data) {
//             newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
//         }
//         console.log(newData)
//         return newData
//     }],
//     paramsSerializer: function(params) {
//         console.log(params)
//         return Qs.stringify(params);
//     }
// })
// export const getters = {
//     getContact(state) {
//         return state.contact
//     }
// }

export const getters = {
    getFaq(state) {
        return state.faq.data
    },
    pickFaq(state) {
        return state.pickFaq
    },
    getToken(state){
        return state.loginState.token
    },
    getLoginState(state){
        console.log(state.loginState)
        return state.loginState
    },
    getRegState(state){
        console.log(state.regState)
        return state.regState
    },
    getActiveState(state){
        return state.activeState
    },
    getUploadState(state){
        return state.uploadState
    }
}

export const actions = {
    actionContact({commit}, data){
        const params = {
            name: data.name,
            tel: data.tel,
            content: data.content,
            service: data.service,
            mail: data.mail
        }
        axios.post('/api/contact/add_contact/', Qs.stringify(params))
            .then(function(response){
                console.log(response)
                data.finished = true
                commit(types.CONTACT, data)
            })
            .catch(function(error){
                console.log(error.response);
            })

    },
    actionFaq({commit}){
        axios.get('/api/faq/list/')
            .then(function(response){
                console.log(response.data)
                commit(types.FAQ, response.data)
            })
            .catch(function(error){
                console.log(error.response);
            })
    },
    actionPickFaq({commit}, no){
        axios.get('/api/faq/list/')
            .then(function(response){
                commit(types.PICK_FAQ, response.data.data[no])
            })
            .catch(function(error){
                console.log(error.response);
            })
    },
    actionLogin({commit}, data){
        const params = {
            mail: data.mail,
            password: data.password
        }
        return axios.post('/api/member/login', Qs.stringify(params))
            .then(function(response){
                console.log(response)
                commit(types.LOGIN, response.data)
            })
            .catch(function(error){
                console.log(error.response)
                commit(types.LOGIN, error.response.data)
            })
    },
    actionRegister({commit}, data){
        const params = {
            name: data.name,
            mail: data.mail,
            password: data.password
        }
        return axios.post('/api/member/registration', Qs.stringify(params))
            .then(function(response){
                console.log(response)
                commit(types.REGISTRATION, response.data)
            })
            .catch(function(error){
                console.log(error.response)
                commit(types.REGISTRATION, error.response.data)
            })
    },
    actionActiveAccount({commit}, data){
        console.log(decodeURI(data))
        return axios.get('/api/member/auth_registration?auth_code=' + data)
            .then(function(response){
                console.log(response)
                commit(types.ACTIVE, response.data)
            })
            .catch(function(error){
                console.log(error.response)
                commit(types.ACTIVE, error.response.data)
            })
    },
    actionResendAuthCode({commit}, mail){
        let params = {
            mail: mail
        }
        return axios.post('/api/member/recertification', Qs.stringify(params))
            .then(function(response){
                commit(types.RESEND_AUTH_CODE, response.data)
                console.log(response)
            })
            .catch(function(error){
                commit(types.RESEND_AUTH_CODE, error.response.data)
                console.log(error.response)
            })
    },
    actionUploadPhoto({commit}, img){
        let formData = new FormData()
        formData.append('file', img)
        let params = {
            file: img
        }
        console.log(formData)
        /*return fetch('/api/member/upload_profile_picture', { // Your POST endpoint
        method: 'POST',
        headers:{
            Authorization: `seoo.demo.pnetwork.com.tw ${token}`
        },
        body: formData // This is your file object
      }).then(function(response){
            console.log(response.json())
            commit(types.UPLOAD_PHOTO, response.data)

        })
        .catch(function(error){
            console.log(axios.headers)
            commit(types.UPLOAD_PHOTO, error.response.data)

        })*/
        return axios.post('/api/member/upload_profile_picture', formData )
            .then(function(response){
                commit(types.UPLOAD_PHOTO, response.data)
                console.log(response)
            })
            .catch(function(error){
                console.log(axios.headers)
                commit(types.UPLOAD_PHOTO, error.response.data)

            })
    }

}