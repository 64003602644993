<template>
    <div class="footer__analysis mb-hide">
        <div class="inner">
            <label-move-input parent-class="analysis--footer"></label-move-input>
        </div>
    </div>
</template>
<script>
    import labelMoveInput from './labelMoveInput.vue';
    export default {
        components: {
            labelMoveInput
        }
    }
</script>