<template>
    <div class="content">
        <hero>點數購買</hero>
        <div class="payment charge section">
            <div class="section__content section__content--padding-tb-less">
                <div class="inner">
                    <div class="steps">
                        <div class="steps__item steps__item--on">
                            <p class="steps__name">購買點數</p>
                        </div>
                        <div class="steps__item" :class="{'steps__item--on': (nowStep>=2)}">
                            <p class="steps__name">資料確認 &<br class="pc-hide"> 付款</p>
                        </div>
                        <div class="steps__item" :class="{'steps__item--on': (nowStep>=3)}">
                            <p class="steps__name">註冊 &<br class="pc-hide"> 付款完成</p>
                        </div>
                    </div>

                    <div v-if='nowStep!==3' class="payment__container charge__container">
                        <h2 class="text-center block__title block__title--noline block__title--nopadding">購買點數</h2>
                        <div class="payment__sets payment__block">
                            <div class="data-sheet data-sheet--charge text-left--atsmall" id="chargePlans">
                                <div class="flexbox data-sheet__row data-sheet__row--head mb-hide">
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atmedium text-center">選擇</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 text-center">點數</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 text-center">使用期限</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--4-atmedium"><span class="text-center data-sheet__sub-cell">金額</span></div>
                                </div>
                                <div class="flexbox flexbox--column-atsmall flexbox--column-v-center-atsmall flexbox--nomargin-atsmall flexbox--wrap-atsmall data-sheet__row data-sheet__row--fixed-height-atsmall data-sheet__row--odd">
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atmedium flexbox__col--2-atsmall flexbox__row flexbox__row--full-atsmall data-sheet__col--v-center-atsmall text-center"><input type="radio" checked class="form__radio" id="point1" name="buyPoints"><label class="form__radio-label" for="point1"></label></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3  flexbox__row flexbox__col--10-atsmall text-center text-left--atsmall"><p class="data-sheet__item-name pc-hide">點數</p>1點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3  flexbox__row flexbox__col--10-atsmall text-center text-left--atsmall"><p class="data-sheet__item-name pc-hide">使用期限</p>30天</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--4-atmedium flexbox__row flexbox__col--10-atsmall"><p class="data-sheet__item-name pc-hide">金額</p><span class="text-center data-sheet__sub-cell text-left--atsmall">$299</span><span class="data-sheet__sub-hint"></span></div>
                                </div>
                                <div class="flexbox flexbox--column-atsmall flexbox--column-v-center-atsmall flexbox--nomargin-atsmall flexbox--wrap-atsmall data-sheet__row data-sheet__row--fixed-height-atsmall">
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atmedium flexbox__col--2-atsmall flexbox__row flexbox__row--full-atsmall data-sheet__col--v-center-atsmall text-center"><input type="radio" class="form__radio" id="point5" name="buyPoints"><label class="form__radio-label" for="point5"></label></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3  flexbox__row flexbox__col--10-atsmall text-center text-left--atsmall"><p class="data-sheet__item-name pc-hide">點數</p>5點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3  flexbox__row flexbox__col--10-atsmall text-center text-left--atsmall"><p class="data-sheet__item-name pc-hide">使用期限</p>180天</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--4-atmedium flexbox__row flexbox__col--10-atsmall"><p class="data-sheet__item-name pc-hide">金額</p><span class="text-center data-sheet__sub-cell text-left--atsmall">$1346</span><span class="data-sheet__sub-hint">9折優惠，省$149</span></div>
                                </div>
                                <div class="flexbox flexbox--column-atsmall flexbox--column-v-center-atsmall flexbox--nomargin-atsmall flexbox--wrap-atsmall data-sheet__row data-sheet__row--fixed-height-atsmall">
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atmedium flexbox__col--2-atsmall flexbox__row flexbox__row--full-atsmall data-sheet__col--v-center-atsmall text-center"><input type="radio" class="form__radio" id="point10" name="buyPoints"><label class="form__radio-label" for="point10"></label></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3  flexbox__row flexbox__col--10-atsmall text-center text-left--atsmall"><p class="data-sheet__item-name pc-hide">點數</p>10點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3  flexbox__row flexbox__col--10-atsmall text-center text-left--atsmall"><p class="data-sheet__item-name pc-hide">使用期限</p>365天</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--4-atmedium flexbox__row flexbox__col--10-atsmall"><p class="data-sheet__item-name pc-hide">金額</p><span class="text-center data-sheet__sub-cell text-left--atsmall">$2392</span><span class="data-sheet__sub-hint">8折優惠，省$598</span></div>
                                </div>
                                <div class="flexbox flexbox--column-atsmall flexbox--column-v-center-atsmall flexbox--nomargin-atsmall flexbox--wrap-atsmall data-sheet__row data-sheet__row--fixed-height-atsmall">
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atmedium flexbox__col--2-atsmall flexbox__row flexbox__row--full-atsmall data-sheet__col--v-center-atsmall text-center"><input type="radio" class="form__radio" id="point20" name="buyPoints"><label class="form__radio-label" for="point20"></label></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3  flexbox__row flexbox__col--10-atsmall text-center text-left--atsmall"><p class="data-sheet__item-name pc-hide">點數</p>20點</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3  flexbox__row flexbox__col--10-atsmall text-center text-left--atsmall"><p class="data-sheet__item-name pc-hide">使用期限</p>730天</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--4-atmedium flexbox__row flexbox__col--10-atsmall"><p class="data-sheet__item-name pc-hide">金額</p><span class="text-center data-sheet__sub-cell text-left--atsmall">$4186</span><span class="data-sheet__sub-hint">7折優惠，省$1794</span></div>
                                </div>
                            </div>
                            <p v-if="nowStep === 2" class="data-sheet__under text-right"><a @click="goStep(1)" href="javascript:;" class="inline-link">重新選擇購買點數</a></p>
                        </div>

                        <div class="charge__invoice">
                            <h2 class="text-center block__title block__title--noline block__title--nopadding">發票資料</h2>
                            <div class="payment__invoice payment__block">
                            <form class="form">
                                <ul class="form__list">
                                    <li class="form__item">
                                        <label for="" class="form__label">開立發票</label>
                                        <div class="form__multi-blanks">
                                            <ul class="form__list">
                                                <li class="form__item form__item--invoice-radio">
                                                    <input type="radio" v-model="invoiceType" class="form__radio" name="inoviceType" id="invoicePersonal"  value="personal"><label for="invoicePersonal" class="form__radio-label">個人(二聯式發票)</label>
                                                </li>
                                                <li class="form__item form__item--invoice-radio">
                                                    <input type="radio" v-model="invoiceType" class="form__radio" name="inoviceType" id="invoiceCompany"  value="company"><label for="invoiceCompany" class="form__radio-label">公司(三聯式發票)</label>
                                                </li>
                                                <li class="form__item form__item--invoice-radio">
                                                    <input type="radio" v-model="invoiceType" class="form__radio" name="inoviceType" id="invoiceDonate"  value="donate"><label for="invoiceDonate" class="form__radio-label">捐贈發票</label>
                                                </li>
                                                <li v-if="invoiceType==='company'" class="form__item">
                                                    <label for="" class="form__label">公司抬頭</label>
                                                    <input type="text" class="form__input">
                                                    <div class="form__saved form__saved--hide"></div>
                                                </li>
                                                <li v-if="invoiceType==='company'" class="form__item">
                                                    <label for="" class="form__label">統一編號</label>
                                                    <input type="text" class="form__input">
                                                    <div class="form__saved form__saved--hide"></div>
                                                </li>
                                            </ul>
                                        </div>
                                        <p class="form__under"></p>
                                    </li>
                                    <li class="form__item">
                                        <label for="" class="form__label">寄送地址</label>
                                        <div class="form__multi-blanks">
                                            <ul class="form__list">
                                                <li class="form__item">
                                                    <div class="form__select">
                                                        <div class="custom-select">
                                                            <input class="custom-select__input" type="text" v-model="contry">
                                                            <select class="custom-select__select">
                                                                <option>{{contry}}</option>
                                                                <option>{{contry}}</option>
                                                            </select>
                                                            <i class="fas fa-caret-down"></i>
                                                        </div>
                                                    </div>
                                                    <div class="form__saved form__saved--hide"></div>
                                                </li>
                                                <li class="form__item">
                                                    <div class="form__select">
                                                        <div class="custom-select">
                                                            <input class="custom-select__input" type="text" v-model="area">
                                                            <select class="custom-select__select">
                                                                <option>{{area}}</option>
                                                                <option>{{area}}</option>
                                                            </select>
                                                            <i class="fas fa-caret-down"></i>
                                                        </div>
                                                    </div>
                                                    <div class="form__saved form__saved--hide"></div>
                                                </li>
                                                <li class="form__item">
                                                    <input type="text" class="form__input" placeholder="郵遞區號">
                                                    <div class="form__saved form__saved--hide"></div>
                                                </li>
                                                <li class="form__item">
                                                    <input type="text" class="form__input" placeholder="地址">
                                                    <div class="form__saved form__saved--hide"></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="form__item">
                                        <label for="" class="form__label">聯絡電話</label>
                                        <input type="text" class="form__input">
                                        <div class="form__saved form__saved--hide"></div>
                                    </li>
                                </ul>
                                <p v-if="nowStep === 1" class="button-line button-line--align-center button-line--underform"><a @click="goStep(2)" href="javascript:;" class="btn normal-btn">下一步  》</a></p>

                            </form>
                            </div>
                            <p v-if="nowStep === 2" class="data-sheet__under text-right"><a @click="goStep(1)" href="javascript:;" class="inline-link">重新填寫發票資料</a></p>
                        </div>

                        <div v-if="nowStep == 2" class="charge__step2">
                            <h2 class="text-center block__title block__title--noline block__title--nopadding">付款資料</h2>

                            <div class="payment__block charge__cards">
                                <ul class="form__list form__list--margin-lr">
                                    <li class="form__item">
                                        <label for="" class="form__label form__label--large">信用卡資料</label>
                                        <p class="form__under form__under--space-large form__under--after-label">* 持卡人需和帳號擁有人同名</p>
                                    </li>
                                </ul>
                                <credit-card type="pay"></credit-card>
                            </div>
                            <p class="button-line button-line--align-center button-line--underform"><a @click="goStep(3)" href="javascript:;" class="btn normal-btn">確認資料 & 付款 》</a></p>
                        </div>
                    </div>

                    <div v-else class="payment__container charge__container">
                        <article class="article article--message">
                            <h2 class="article__title">註冊 & 付款成功</h2>
                            <div class="article__content">
                                <p>她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了然靜成，中天業…… 她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……</p>
                                <p>麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期</p>
                            </div>
                        </article>
                    </div>

                </div>
            </div>
        </div>
        <footer-analysis v-if="nowStep===3"></footer-analysis>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import Vue from 'vue'
    import hero from './../components/hero.vue'
    import footerAnalysis from '../components/footerAnalysis.vue'
    import creditCard from './../components/creditCard.vue'

    export default {
        data(){
            return{
                contry: '縣市',
                area: '鄉鎮市區',
                showBox: 'none',
                nowStep: 1,
                invoiceType: String,
            }
        },
        components: {
            hero,
            creditCard,
            footerAnalysis
        },
        mounted(){
            document.querySelectorAll('input[name="buyPoints"]').forEach((el, key)=>{
                el.addEventListener('change', (e)=>{
                    document.querySelectorAll('#chargePlans .data-sheet__row').forEach((el, key)=>{
                        if(el.querySelector('input:checked') !== null){
                            el.classList.add('data-sheet__row--odd')
                        }else{
                            el.classList.remove('data-sheet__row--odd')
                        }
                    })
                })
            })


        },
        methods:{
            goStep: function(step){
                this.nowStep = step
                if(step==1){
                    document.querySelectorAll('#chargePlans .data-sheet__row').forEach((el, key)=>{
                        el.style.display = ''
                        el.style.border = ''
                    })
                    document.querySelectorAll('.form__item--invoice-radio').forEach((el, key)=>{
                        el.classList.remove('form__item--hide')
                        el.classList.remove('form__item--no-margin')
                    })
                    document.querySelectorAll('.charge__invoice input.form__input').forEach((el, key)=>{
                        el.style.display = ''
                        el.nextElementSibling.classList.add('form__saved--hide')
                    })
                    document.querySelectorAll('.form__select').forEach((el, key)=>{
                        el.style.display = ''
                        el.nextElementSibling.classList.add('form__saved--hide')
                    })
                }else if(step>=2){
                    document.querySelectorAll('#chargePlans .data-sheet__row').forEach((el, key)=>{
                        if(el.querySelector('input:checked') !== null){
                            el.style.display = ''
                            el.style.border = 'none'
                        }else{
                            el.style.display = 'none'
                            el.style.border = ''
                        }
                    })
                    document.querySelectorAll('.form__item--invoice-radio').forEach((el, key)=>{
                        if(el.querySelector('input:checked') !== null){
                            el.classList.remove('form__item--hide')
                            el.classList.add('form__item--no-margin')
                        }else{
                            el.classList.add('form__item--hide')
                            el.classList.remove('form__item--no-margin')
                        }
                    })

                    document.querySelectorAll('.charge__invoice input.form__input').forEach((el, key)=>{
                        el.style.display = 'none'
                        el.nextElementSibling.classList.remove('form__saved--hide')
                        el.nextElementSibling.innerHTML = el.value
                    })
                    document.querySelectorAll('.form__select').forEach((el, key)=>{
                        el.style.display = 'none'
                        el.nextElementSibling.classList.remove('form__saved--hide')
                        el.nextElementSibling.innerHTML = el.querySelector('input').value
                    })
                }
            }
        }
    }
</script>