<template>
        <div class="content">
            <hero>近期收錄</hero>
            <div class="collection section">
                <div class="inner">
                    <div class="sort-drop text-right text-center--atsmall">排序依
                        <div class="custom-select ">
                            <input class="custom-select__input" type="text" v-model="sortBy">
                            <select class="custom-select__select" v-model="sortType">
                                <option v-for="(item, index) in sortTypes" :value="item.value">{{ item.text }}</option>
                            </select>
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>

                    <div class="collection__container">
                        <div class="collection__block collection__block--multiline" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="collection__block__inner">
                                <div class="scorecirc scorecirc--responsive score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="collection__block collection__block--multiline" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="collection__block__inner">
                                <div class="scorecirc scorecirc--responsive score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="collection__block collection__block--multiline" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="collection__block__inner">
                                <div class="scorecirc scorecirc--responsive score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="collection__block collection__block--multiline" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="collection__block__inner">
                                <div class="scorecirc scorecirc--responsive score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="collection__block collection__block--multiline" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="collection__block__inner">
                                <div class="scorecirc scorecirc--responsive score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="collection__block collection__block--multiline" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="collection__block__inner">
                                <div class="scorecirc scorecirc--responsive score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="collection__block collection__block--multiline" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="collection__block__inner">
                                <div class="scorecirc scorecirc--responsive score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="collection__block collection__block--multiline" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="collection__block__inner">
                                <div class="scorecirc scorecirc--responsive score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="text-center">
                        <a class="section__more" href="javascript:;">看更多</a>
                </div>

            </div>
            <footer-analysis></footer-analysis>
        </div>
    </template>
    <script>
        import {mapGetters, mapActions} from 'vuex'
        import Vue from 'vue'
        import hero from './../components/hero.vue'
        import footerAnalysis from '../components/footerAnalysis.vue'

        export default {
            data(){
                return{
                    sortType: "1",
                    sortTypes: [{
                        value: "1",
                        text: "檢測日期 新→舊"
                    },{
                        value: "2",
                        text: "檢測日期 舊→新"
                    }]
                }
            },
            computed: {
                sortBy(){
                    return this.sortTypes[this.sortTypes.findIndex(x => x.value == this.sortType)].text
                }
            },
            components:{
                hero,
                footerAnalysis
            }
        }
    </script>