<template>
    <div class="content">
        <hero>會員專區</hero>
        <div class="record section">
            <div class="section__content section__content--padding-tb-less">
                <div class="inner">
                    <div class="crumb">
                        <a href="" class="crumb__text">會員專區</a>
                        <span href="" class="crumb__text">歷史檢測報告</span>
                    </div>

                    <div class="record__container">
                        <h2 class="text-center block__title block__title--noline block__title--nopadding">歷史檢測報告</h2>
                        <p class="text-center sub-block__title">僅完整版檢測提供報告下載，每份報告從報告產生日起保存兩年</p>
                        <div class="record__block">
                            <div class="data-sheet data-sheet--single data-sheet--record">
                                <div class="flexbox data-sheet__row data-sheet__row--head mb-hide text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2">檢測日</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--6 text-left">網址</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2">報告類型</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2">下載報告</div>
                                </div>
                                <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row--odd text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">檢測日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--6 text-left"><span class="data-sheet__item-name text-left">網址</span>www.nowilldesign.com</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">報告類型</span><strong>完整版</strong></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">下載報告</span><a href="javascript:;" class="inline-link">下載PDF</a></div>
                                </div>

                                <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">檢測日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--6 text-left"><span class="data-sheet__item-name text-left">網址</span>www.nowilldesign.com</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">報告類型</span><strong>完整版</strong></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">下載報告</span><a href="javascript:;" class="inline-link">下載PDF</a></div>
                                </div>

                                <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row--odd text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">檢測日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--6 text-left"><span class="data-sheet__item-name text-left">網址</span>www.nowilldesign.com</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">報告類型</span>簡易版</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">下載報告</span>-</div>
                                </div>

                                <div class="flexbox flexbox--column-atsmall data-sheet__row data-sheet__row text-center">
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">檢測日</span>2018/01/01 00:00</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--6 text-left"><span class="data-sheet__item-name text-left">網址</span>www.nowilldesign.com</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">報告類型</span><strong>完整版</strong></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--2"><span class="data-sheet__item-name text-left">下載報告</span><a href="javascript:;" class="inline-link">下載PDF</a></div>
                                </div>



                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import Vue from 'vue'
    import hero from './../components/hero.vue'
    import { slideDown, slideUp, slideStop, isVisible } from 'slide-anim';
    import scrollTo from 'animated-scroll-to'

    export default {
        data(){
            return{
            }
        },
        components: {
            hero
        },
        methods:{
            toggleSubSheet(e){
                let toggleID = e.currentTarget.getAttribute('data-sub')
                let toggle = document.querySelector("#"+toggleID)
                if(toggle.classList.contains('unfold')){
                    toggle.classList.remove('unfold')
                    slideUp(toggle)
                    e.currentTarget.textContent = '+'
                }else{
                    toggle.classList.add('unfold')
                    slideDown(toggle)
                    e.currentTarget.textContent = '-'
                }
                // setTimeout(()=>{
                //     scrollTo(toggle, {
                //         offset: -60
                //     })
                // }, 100)
            }
        },
    }
</script>