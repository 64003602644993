<template>
    <nav class="sidenav">
        <h1 class="hide-title">side navigation</h1>
        <ul class="sidenav__list">
            <li class="sidenav__item">
                <a href="#top" class="sidenav__link" @click="scrollToSec">
                    <span class="icon"><i class="fas fa-arrow-up fa-fw"></i></span>
                    <span class="text">回頂端</span>
                </a>
            </li>
            <li class="sidenav__item">
                <a href="#seo" class="sidenav__link" @click="scrollToSec">
                    <span class="icon"><i class="fas fa-search fa-fw"></i></span>
                    <span class="text">SEO</span>
                </a>
            </li>
            <li class="sidenav__item">
                <a href="#usability" class="sidenav__link" @click="scrollToSec">
                    <span class="icon"><i class="far fa-hand-pointer fa-fw"></i></span>
                    <span class="text">易用性</span>
                </a>
            </li>
            <li class="sidenav__item">
                <a href="#top" class="sidenav__link" @click="scrollToSec">
                    <span class="icon"><i class="fas fa-mobile-alt fa-fw"></i></span>
                    <span class="text">相容性</span>
                </a>
            </li>
            <li class="sidenav__item">
                <a href="#top" class="sidenav__link" @click="scrollToSec">
                    <span class="icon"><i class="fas fa-rocket fa-fw"></i></span>
                    <span class="text">技術</span>
                </a>
            </li>
            <li class="sidenav__item">
                <a href="#top" class="sidenav__link" @click="scrollToSec">
                    <span class="icon"><i class="far fa-handshake fa-fw"></i></span>
                    <span class="text">社群</span>
                </a>
            </li>
            <li class="sidenav__item">
                <a href="#top" class="sidenav__link" @click="scrollToSec">
                    <span class="icon"><i class="fas fa-users fa-fw"></i></span>
                    <span class="text">訪客</span>
                </a>
            </li>
            <li class="sidenav__item">
                <a href="#top" class="sidenav__link" @click="scrollToSec">
                    <span class="icon"><i class="fas fa-link fa-fw"></i></span>
                    <span class="text">連結分析</span>
                </a>
            </li>
        </ul>
    </nav>
</template>
<script>
    import Vue from 'vue'
    import scrollTo from 'animated-scroll-to'
    import removeClass from './../assets/js/moudles/removeClass.js'
    import './../../node_modules/waypoints/lib/noframework.waypoints.min.js'

    export default {
        mounted() {

            window.addEventListener('scroll', this.controlSideNav)
            window.dispatchEvent(new Event('scroll'))

            document.querySelectorAll('.section[id]').forEach(function (item, key) {
                var currentSection = item;
                new Waypoint({
                    element: currentSection,
                    handler: function (dir) {
                        var matchingTab = currentSection.getAttribute('id')
                        //console.log(`${dir} ${matchingTab}`)
                        if (dir == 'down') {
                            removeClass(`.sidenav__link:not([href="#${matchingTab}"])`, 'on')
                            document.querySelector(`.sidenav__link[href="#${matchingTab}"]`).classList.add('on')

                        }
                    },
                    offset: '20%'
                });
                new Waypoint({
                    element: currentSection,
                    handler: function (dir) {
                        if (dir == 'up') {
                            var matchingTab = currentSection.getAttribute('id')
                            removeClass(`.sidenav__link:not([href="#${matchingTab}"])`, 'on')
                            document.querySelector(`.sidenav__link[href="#${matchingTab}"]`).classList.add('on')

                        }

                    },
                    offset: "-40%"
                })
            });

            Waypoint.refreshAll()

        },
        beforeDestroy(){
            //console.log('before destroy')
            window.removeEventListener('scroll', this.controlSideNav)
        },
        methods: {
            scrollToSec(e) {
                scrollTo(document.querySelector(e.currentTarget.getAttribute('href')), {
                    offset: document.querySelector('header').clientHeight * -1
                })
            },
            controlSideNav(){
                let detectPoint = document.querySelector('#seo').getBoundingClientRect().top - window.innerHeight/2
                if(document.body.scrollTop >= detectPoint){
                    document.querySelector('.sidenav').classList.add('show')
                }else{
                    removeClass('.sidenav', 'show')
                }
            }
        }
    }

</script>