<template>
    <div class="content">
        <hero>會員專區</hero>
        <div v-if="!logged" class="member section">
            <div class="section__content section__content--padding-tb">
                <div class="inner">
                    <div class="member__head text-center">
                        <h2 class="block__title block__title--noline block__title--nopadding">社群登入/註冊</h2>
                        <p class="member__sns">
                            <a href="" class="btn"><i class="fab fa-facebook-square"></i></a>
                            <a href="" class="btn"><i class="fab fa-google"></i></a>
                        </p>
                    </div>

                    <div class="member__login">
                        <div class="member__block member__block--login">
                            <div class="text-center">
                                <h3 class="block__title block__title--noline block__title--nopadding-atsmall">會員登入</h3>
                            </div>
                            <div class="member__block-inner">
                                <form class="form">
                                    <ul class="form__list">
                                        <li class="form__item"  :class="{'form__item--warning': errors.has('email')}">
                                            <label for="email" class="form__label">會員帳號</label>
                                            <input type="email" class="form__input" v-model="email" placeholder="註冊信箱" name="email" v-validate="'required|email'">
                                            <p class="form__under">{{ errors.first('email') }}</p>
                                        </li>
                                        <li class="form__item" :class="{'form__item--warning': errors.has('password')}">
                                            <label for="email" class="form__label">會員密碼</label>
                                            <input type="password" class="form__input" v-model="password" placeholder="請注意大小寫" name="password" v-validate="'required|password'">
                                            <p class="form__under">{{ errors.first('password') }}<router-link :to="{name:'member-forgetpwd'}" class="form__under-link" style="float: right;">忘記密碼？</router-link></p>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                            <p class="button-line button-line--center">
                                <a @click="login()" href="javascript:;" class="btn normal-btn">登入</a>
                            </p>
                        </div>
                        <div class="member__block member__block--reg">
                            <div class="text-center">
                                <h3 class="block__title block__title--noline block__title--nopadding-atsmall">加入會員</h3>
                            </div>
                            <div class="member__block-inner">
                                <p class="member__msg text-center">
                                    還沒有會員嗎？請由下方加入我們吧
                                </p>
                            </div>

                            <p class="button-line button-line--center">
                                <router-link :to="{name:'member-register'}" class="btn normal-btn">加入會員</router-link>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div v-else class="member member--profile section">
            <div class="section__content section__content--padding-tb">
                <div class="inner">
                    <div class="profile flexbox flexbox--between flexbox--space-5 flexbox--column-atsmall flexbox--reverse-atsmall">
                        <div class="flexbox__col flexbox__col--8 flexbox__col--9-atmedium">
                            <div class="profile__block">
                                <h3 class="profile__block-title text-center">點數消費紀錄</h3>
                                <div class="profile__block-content">
                                    <ul class="profile__block-list">
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">啟用點數</span>
                                                <span class="profile__text">9999 點</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">點數到期日</span>
                                                <span class="profile__text">2018年12月31日 上午00:00</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">未啟用點數</span>
                                                <span class="profile__text">10 點</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">自動續訂</span>
                                                <span class="profile__text">開啟</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="profile__block">
                                <h3 class="profile__block-title text-center">網站分析歷史紀錄</h3>
                                <div class="profile__block-content">
                                    <ul class="profile__block-list">
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">啟用點數</span>
                                                <span class="profile__text">9999 點</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">點數到期日</span>
                                                <span class="profile__text">2018年12月31日 上午00:00</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">未啟用點數</span>
                                                <span class="profile__text">10 點</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">自動續訂</span>
                                                <span class="profile__text">開啟</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="profile__block">
                                <h3 class="profile__block-title text-center">信用卡結帳管理</h3>
                                <div class="profile__block-content">
                                    <ul class="profile__block-list">
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">啟用點數</span>
                                                <span class="profile__text">9999 點</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">點數到期日</span>
                                                <span class="profile__text">2018年12月31日 上午00:00</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">未啟用點數</span>
                                                <span class="profile__text">10 點</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p class="profile__block-data">
                                                <span class="profile__label">自動續訂</span>
                                                <span class="profile__text">開啟</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="flexbox__col flexbox__col--4 flexbox__col--3-atmedium profile__user-block">
                            <div class="profile__user">
                                <div class="profile__user-inner">
                                    <div class="profile__user-content">
                                        <div class="profile__user-avatar" :style="{ backgroundImage: `url(${require('./../assets/images/dummy.png')})` }"></div>
                                        <div class="profile__user-data">
                                            <p class="profile__user-name">王大明</p>
                                            <div class="button-line">
                                                <router-link :to="{name:'member-user'}" class="btn small-btn small-btn--full small-btn--reverse profile__user-detail-btn">修改資料</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile__user-point text-right">
                                        <p class="profile__upper-label">目前有效點數</p>
                                        <p class="profile__user-nowpoint">
                                            <span class="num">9999</span>點
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="profile__user-under text-center">
                                <router-link :to="{name:'charge'}" class="btn normal-btn normal-btn--full normal-btn--xl">購買點數</router-link>
                                <a @click="logged=false" href="javascript:;" class="profile__logout-btn">會員登出</a>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
        <lightbox box-title="登入失敗" :show-box="showBox" button-align="center" box-id="loginErrorBox" @closebox="showBox = 'none'">
            <template slot="msg">
                <p class="text-center">{{ loginState.msg }}</p>
            </template>
            <template slot="buttons">
                <a href="javascript:;" class="btn normal-btn" @click="resend()" v-if="loginState.actived === false">重寄啟用信</a>
                <a href="javascript:;" class="btn normal-btn normal-btn--40" @click="showBox = 'none'">確定</a>
            </template>
        </lightbox>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex'
    //import Vue from 'vue'
    //import {Validator} from 'vee-validate'
    import hero from './../components/hero.vue'
    import lightbox from './../components/lightbox.vue'
    import Cookies from './../assets/js/moudles/controlCookie.js'


    export default {
        data(){
            return{
                logged: false,
                email: '',
                password: '',
                showBox: 'none',
            }
        },
        components:{
            hero,
            lightbox
        },
        computed:{
            ...mapGetters({
                token: 'getToken',
                loginState: 'getLoginState'
            })
        },
        mounted(){
            this.cookies = new Cookies()
            this.checkToken()
        },
        methods:{
            ...mapActions([
                'actionLogin',
                'actionResendAuthCode'
            ]),
            checkToken(){
                console.log(this.token)
                if(this.token != '' && this.token != undefined && this.token != null){
                    this.logged = true
                    this.cookies.setCookie('token', this.token, 1)
                }else{
                    this.logged = false
                    if(this.loginState.actived === false) {
                        this.showBox = 'loginErrorBox'
                    }else if(this.loginState.msg){
                        this.showBox = 'loginErrorBox'
                    }
                }
            },
            login(){
                this.$validator.validateAll().then((result)=>{
                    if(result) this.$store.dispatch('actionLogin', {mail: this.email, password: this.password})
                    .then(()=>{
                        this.checkToken()

                    })
                })

            },
            resend(){
                this.showBox = 'none'
                this.$store.dispatch('actionResendAuthCode', this.email)
                    .then(()=>{
                        //this.showBox = 'loginErrorBox'
                    })
            }
        }
    }
</script>