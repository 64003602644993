import Vue from 'vue'
import * as types from '../mutations_type'

// states

export const state = {
    article: {},
}

export const mutations = {
    [types.ARTICLE](state, article) {
        state.article = article
    }
}