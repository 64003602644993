<template>
    <div class="hero">
        <div class="hero__bg">
            <picture>
                <source srcset="./../assets/images/architecture-3357028_1920.png" :media="atSmall"><img srcset="./../assets/images/architecture-3357028_1920.png" alt="">
            </picture>
        </div>
        <div class="hero__content">
            <div class="inner">
                <h2 class="hero__title"><slot></slot></h2>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                atSmall: '(max-width:640px)'
            }
        },
    }
</script>