<template>
    <div class="content">

        <visual></visual>
        <section class="section section--slider">
            <div class="section__head">
                <div class="inner">
                    <h2 class="section__title">近期收錄</h2><router-link class="section__more" :to="{name: 'collection'}">看更多</router-link>
                </div>
            </div>
            <div class="section__content">
                <div class="slider swiper-container">
                    <div class="slider__wrapper swiper-wrapper">
                        <div class="slider__slide swiper-slide collection__block" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="slider__inner">
                                <!-- score-a 80~100-->
                                <!-- score-b 60~79-->
                                <!-- score-c 30~59-->
                                <!-- score-d 0~29-->
                                <div class="scorecirc scorecirc--slider score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="slider__name collection__block-name">nowilldesign.comnowilldesign.comnowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="slider__slide swiper-slide collection__block" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="slider__inner">
                                <div class="scorecirc scorecirc--slider score-b">
                                    <div class="scorecirc__score"><span>62</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="slider__name collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="slider__slide swiper-slide collection__block" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="slider__inner">
                                <div class="scorecirc scorecirc--slider score-c">
                                    <div class="scorecirc__score"><span>50</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="slider__name collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="slider__slide swiper-slide collection__block" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="slider__inner">
                                <div class="scorecirc scorecirc--slider score-d">
                                    <div class="scorecirc__score"><span>5</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="slider__name collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                        <div class="slider__slide swiper-slide collection__block" :style="{ backgroundImage: `url(${require('./../assets/images/sample-a.png')})` }">
                            <div class="slider__inner">
                                <div class="scorecirc scorecirc--slider score-a">
                                    <div class="scorecirc__score"><span>80</span></div>
                                    <div class="scorecirc__perfect"><span>100</span></div>
                                </div>
                                <p class="slider__name collection__block-name">nowilldesign.com</p><a class="cover-btn" href=""></a>
                            </div>
                        </div>
                    </div><a class="swiper-button-prev slider__prev-btn" href="javascript:;"><i class="fa fa-angle-left"></i></a><a class="swiper-button-next slider__next-btn" href="javascript:;"><i class="fa fa-angle-right"></i></a>
                </div>
            </div>
        </section>
        <section class="section section--even section--seo">
            <div class="section__head">
                <div class="inner">
                    <h2 class="section__title">SEO服務</h2>
                </div>
            </div>
            <div class="section__content">
                <div class="interlock">
                    <div class="interlock__left">
                        <picture class="interlock__pic">
                            <source srcset="./../assets/images/sample-b.png" :media="atSmall"><img srcset="./../assets/images/sample-b.png" alt="">
                        </picture>
                    </div>
                    <div class="interlock__right">
                        <div class="interlock__content">
                            <h3 class="block__title"><span class="icon icon-seo-1"><img src="./../assets/images/icon-seo-1.svg" alt="SEOO"></span><span class="text">什麼是SEO</span></h3>
                            <div class="interlock__text">
                                <p>她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了然靜成，中天業……</p><a class="go-btn base-btn" href="">立即檢測</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="interlock interlock--reverse">
                    <div class="interlock__left">
                        <picture class="interlock__pic">
                            <source srcset="./../assets/images/sample-c.png" :media="atSmall"><img srcset="./../assets/images/sample-c.png" alt="">
                        </picture>
                    </div>
                    <div class="interlock__right">
                        <div class="interlock__content">
                            <h3 class="block__title"><span class="icon icon-seo-1"><img src="./../assets/images/icon-seo-2.svg" alt="SEOO"></span><span class="text">SEO能做什麼</span></h3>
                            <div class="interlock__text">
                                <p>她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了然靜成，中天業……</p><a class="go-btn base-btn" href="">立即檢測</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="interlock">
                    <div class="interlock__left">
                        <picture class="interlock__pic">
                            <source srcset="./../assets/images/sample-d.png" :media="atSmall"><img srcset="./../assets/images/sample-d.png" alt="">
                        </picture>
                    </div>
                    <div class="interlock__right">
                        <div class="interlock__content">
                            <h3 class="block__title"><span class="icon icon-seo-1"><img src="./../assets/images/icon-seo-3.svg" alt="SEOO"></span><span class="text"><span class="hide-title">SEOO</span>的SEO</span></h3>
                            <div class="interlock__text">
                                <p>她人專大地位孩來去臺的需沒更來溫未突如家過！興乎買不護的軍其，麼國跟國訴樣人比臺甚類頭我反為天面可時事除養李造議大前高定如去來得東，業房演離小常天行起道腳，定國一題實國包系媽是……期真之都失場任你決小區們：不了然靜成，中天業……</p><a class="go-btn base-btn" href="">立即檢測</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section--points">
            <div class="section__head">
                <div class="inner">
                    <h2 class="section__title">服務點數購買方案</h2>
                </div>
            </div>
            <div class="section__content points">
                <div class="points__overview">
                    <ul class="points__list">
                        <li class="points__item points__item--1">
                            <div class="points__square points__square--1">
                                <div class="points__circle">
                                    <p class="points__num">1<span class="sub">點</span></p>
                                </div>
                                <p class="points__price"><span class="currency">NT$</span><span class="num">299</span></p>
                            </div>
                        </li>
                        <li class="points__item points__item--5">
                            <div class="points__square points__square--5">
                                <div class="points__circle">
                                    <p class="points__num">5<span class="sub">點</span></p>
                                </div>
                                <p class="points__price"><span class="currency">NT$</span><span class="num">1346</span></p>
                            </div>
                            <p class="points__hint">* 9折優惠</p>
                        </li>
                        <li class="points__item points__item--10">
                            <div class="points__square points__square--10">
                                <div class="points__circle">
                                    <p class="points__num">10<span class="sub">點</span></p>
                                </div>
                                <p class="points__price"><span class="currency">NT$</span><span class="num">2392</span></p>
                            </div>
                            <p class="points__hint">* 8折優惠</p>
                        </li>
                        <li class="points__item points__item--20">
                            <div class="points__square points__square--20">
                                <div class="points__circle">
                                    <p class="points__num">20<span class="sub">點</span></p>
                                </div>
                                <p class="points__price"><span class="currency">NT$</span><span class="num">4186</span></p>
                            </div>
                            <p class="points__hint">* 7折優惠</p>
                        </li>
                    </ul>
                    <div class="points__overview-bottom"><a class="points__buybtn normal-btn normal-btn--atsmall-large" href="">購買點數</a>
                        <p class="points__hint">* 依照每次購買的服務點數，服務點數均有使用有效期限制，詳細說明請參考 FAQ。</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section--even section--functions">
            <div class="section__head">
                <div class="inner">
                    <h2 class="section__title">服務使用說明</h2>
                </div>
            </div>
            <div class="section__content">
                <div class="service-introduction">
                    <div class="service-introduction__col service-introduction__col--side mb-hide">
                        <div class="service-introduction__labels">
                            <p class="service-introduction__label">服務項目</p>
                            <p class="service-introduction__label">簡易版分析</p>
                            <p class="service-introduction__label">雙網分析<br>(簡易版)</p>
                            <p class="service-introduction__label">分析記錄</p>
                            <p class="service-introduction__label">完整版分析</p>
                            <p class="service-introduction__label">雙網分析<br>(完整版)</p>
                            <p class="service-introduction__label">前次比較</p>
                        </div>
                        <!-- <div class="service-introduction__under-item"></div> -->
                    </div>
                    <div class="service-introduction__col" @click="unfold">
                        <div class="service-introduction__level">
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-title">
                                    <span class="service-introduction__item-text">非會員</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">簡易版分析</span>
                                    <span class="service-introduction__item-text">免費</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">雙網分析<small>(簡易版)</small></span>
                                    <span class="service-introduction__item-text">免費</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">分析記錄</span>
                                    <span class="service-introduction__item-text">-</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">完整版分析</span>
                                    <span class="service-introduction__item-text">-</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">雙網分析<small>(完整版)</small></span>
                                    <span class="service-introduction__item-text">-</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">前次比較</span>
                                    <span class="service-introduction__item-text">-</span>
                                </p>
                            </div>

                        </div>
                        <!-- <div class="service-introduction__under-item"></div> -->
                    </div>
                    <div class="service-introduction__col" @click="unfold">
                        <div class="service-introduction__level">
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-title">
                                    <span class="service-introduction__item-text">免費會員</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">簡易版分析</span>
                                    <span class="service-introduction__item-text">免費</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">雙網分析<small>(簡易版)</small></span>
                                    <span class="service-introduction__item-text">免費</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">分析記錄</span>
                                    <span class="service-introduction__item-text">免費</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">完整版分析</span>
                                    <span class="service-introduction__item-text">-</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">雙網分析<small>(完整版)</small></span>
                                    <span class="service-introduction__item-text">-</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">前次比較</span>
                                    <span class="service-introduction__item-text">-</span>
                                </p>
                            </div>

                        </div>
                        <!-- <div class="service-introduction__under-item"></div> -->
                    </div>
                    <div class="service-introduction__col" @click="unfold">
                        <div class="service-introduction__level">
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-title">
                                    <span class="service-introduction__item-text">付費會員</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">簡易版分析</span>
                                    <span class="service-introduction__item-text">免費</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">雙網分析<small>(簡易版)</small></span>
                                    <span class="service-introduction__item-text">免費</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">分析記錄</span>
                                    <span class="service-introduction__item-text">免費</span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">完整版分析</span>
                                    <span class="service-introduction__item-text">
                                        <span class="service-introduction__no">1</span>
                                    </span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">雙網分析<small>(完整版)</small></span>
                                    <span class="service-introduction__item-text">
                                        <span class="service-introduction__no">2</span>
                                    </span>
                                </p>
                            </div>
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-label pc-hide">前次比較</span>
                                    <span class="service-introduction__item-text">
                                        <span class="service-introduction__no">1</span>
                                        &nbsp;/免費<span class="super-marker">*</span>
                                    </span>
                                </p>
                            </div>

                        </div>
                        <div class="service-introduction__under-item">
                            <a href="" class="normal-btn normal-btn--full normal-btn--atsmall-large">購買點數</a>
                            <p class="service-introduction__hint service-introduction__hint--indent">*如使用前次/現況比較，則扣取現況分析的一點，如使用兩個前次分析報告的比較即可免費比較</p>
                        </div>
                    </div>
                    <div class="service-introduction__col" @click="unfold">
                        <div class="service-introduction__level">
                            <div class="service-introduction__item">
                                <p class="service-introduction__item-title">
                                    <span class="service-introduction__item-text">企業用戶</span>
                                </p>
                            </div>
                            <div class="service-introduction__item service-introduction__item--full">
                                <p class="service-introduction__item-content">
                                    <span class="service-introduction__item-text">大量 / 多人<br>優惠方案<br>請洽客服</span>
                                </p>
                            </div>
                        </div>
                        <!-- <div class="service-introduction__under-item"></div> -->
                    </div>
                </div>

            </div>
        </section>
        <footer-analysis></footer-analysis>
    </div>
</template>

<script>
    import Swiper from 'swiper'
    import visual from '../components/visual.vue'
    import footerAnalysis from '../components/footerAnalysis.vue'
    import scrollTo from 'animated-scroll-to'
    import removeClass from './../assets/js/moudles/removeClass.js'

    export default{
        props:{
            mq: String
        },
        data(){
            return {
                atSmall: '(max-width:640px)',
            }
        },
        computed: {
            slideSpace(){
                return (this.mq === 'Small' || this.mq === 'SS')? 5 : 30
            }
        },
        components:{
            visual,
            footerAnalysis
        },
        mounted(){
            console.log('mq: ')
            console.log(this.mq)

            let mySwiper = new Swiper ('.swiper-container', {
                // Optional parameters
                loop: true,
                slidesPerView: 'auto',
                centeredSlides: 'true',
                spaceBetween: this.slideSpace,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on:{
                    resize: ()=>{
                        mySwiper.params.spaceBetween = this.slideSpace
                        mySwiper.update()
                    }
                }
            });


        },
        methods:{
            unfold: function(e) {
                if(this.mq === 'Small' || this.mq === 'SS'){
                    let anchor = e.currentTarget
                    removeClass('.service-introduction__level.opened, .service-introduction__col.opened', 'opened')
                    e.currentTarget.classList.add('opened')
                    e.currentTarget.querySelector('.service-introduction__level').classList.add('opened')
                    setTimeout(()=>{
                        scrollTo(anchor, {
                            offset: -60
                        })
                    }, 300)
                }

            }
        },
    }

</script>