<template>
    <div v-if="getArticle.data" class="content">
        <hero>{{ getArticle.data.title }}</hero>
        <div class="section">
            <div class="inner">
                <article class="article section__content section__content--padding-tb">
                    <h2 class="article__title">{{ getArticle.data.title }}</h2>
                    <div v-html="getArticle.data.content" class="article__content"></div>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Vue from 'vue'
    import hero from './../components/hero.vue'

    export default{
        computed:{
            ...mapGetters({
                getArticle: 'getArticle',
            }),
        },
        methods:{
            ...mapActions([
                'actionGetArticle'
            ]),
        },
        beforeCreate(){
            //actionGetArticle('privacy')
            this.$store.dispatch('actionGetArticle', this.$route.meta.articleName)
        },
        watch:{
            $route (to, from){
                this.$store.dispatch('actionGetArticle', this.$route.meta.articleName)
            }
        },
        components:{
            hero
        },
    }

</script>