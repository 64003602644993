<template>
    <div class="content">
        <hero>會員專區</hero>
        <div class="payment section">
            <div class="section__content section__content--padding-tb-less">
                <div class="inner">
                    <div class="crumb">
                        <a href="" class="crumb__text">會員專區</a>
                        <span href="" class="crumb__text">信用卡結帳管理</span>
                    </div>

                    <div class="payment__container">
                        <h2 class="text-center block__title block__title--noline block__title--nopadding">付款資料</h2>
                        <div class="payment__sets payment__block">
                            <p class="data-sheet__ahead text-right"><a href="javascript:;" @click="showBox = 'addCardBox'" class="inline-link inline-link--more">新增信用卡&nbsp;<i class="fas fa-plus-circle"></i></a></p>
                            <div class="data-sheet data-sheet--payment text-center text-left--atsmall">
                                <div class="flexbox data-sheet__row data-sheet__row--head">
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atsmall text-center--atsmall">刪除</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col flexbox__col--8-atsmall pc-hide">信用卡資訊</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 mb-hide">信用卡卡號</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 mb-hide">有效期限</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atsmall text-center--atsmall">預設<span class="mb-hide">信用卡</span></div>
                                </div>
                                <div class="flexbox flexbox--column-atsmall flexbox--column-v-center-atsmall flexbox--nomargin-atsmall flexbox--wrap-atsmall data-sheet__row data-sheet__row--fixed-height-atsmall">
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atsmall flexbox__row flexbox__row--full-atsmall data-sheet__col--v-center-atsmall"><a href="" class="payment__delete"><span class="mb-hide">刪除</span><i class="pc-hide fas fa-times"></i></a></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--8-atsmall flexbox__row"><p class="data-sheet__item-name pc-hide">信用卡卡號</p>xxxx xxxx xxxx 1234</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--8-atsmall flexbox__row"><p class="data-sheet__item-name pc-hide">有效期限</p>**/**</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atsmall flexbox__row flexbox__row--full-atsmall data-sheet__col--v-center-atsmall"><input type="radio" checked class="form__radio" id="card01" name="defaultPayment"><label class="form__radio-label" for="card01"></label></div>
                                </div>
                                <div class="flexbox flexbox--column-atsmall flexbox--column-v-center-atsmall flexbox--nomargin-atsmall flexbox--wrap-atsmall data-sheet__row data-sheet__row--fixed-height-atsmall">
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atsmall flexbox__row flexbox__row--full-atsmall data-sheet__col--v-center-atsmall"><a href="" class="payment__delete"><span class="mb-hide">刪除</span><i class="pc-hide fas fa-times"></i></a></div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--8-atsmall flexbox__row"><p class="data-sheet__item-name pc-hide">信用卡卡號</p>xxxx xxxx xxxx 1234</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--8-atsmall flexbox__row"><p class="data-sheet__item-name pc-hide">有效期限</p>**/**</div>
                                    <div class="data-sheet__col flexbox__col flexbox__col--3 flexbox__col--2-atsmall flexbox__row flexbox__row--full-atsmall data-sheet__col--v-center-atsmall"><input type="radio" class="form__radio" id="card02" name="defaultPayment"><label class="form__radio-label" for="card02"></label></div>
                                </div>
                            </div>
                        </div>

                        <h2 class="text-center block__title block__title--noline block__title--nopadding">發票資料</h2>
                        <div class="payment__invoice payment__block">
                            <form class="form">
                                <ul class="form__list">
                                    <li class="form__item">
                                        <label for="" class="form__label">開立發票</label>
                                        <div class="form__multi-blanks">
                                            <ul class="form__list">
                                                <li class="form__item">
                                                    <input type="radio" class="form__radio" name="inoviceType" id="invoicePersonal"><label for="invoicePersonal" class="form__radio-label">個人(二聯式發票)</label>
                                                </li>
                                                <li class="form__item">
                                                    <input type="radio" class="form__radio" name="inoviceType" id="invoiceCompany"><label for="invoiceCompany" class="form__radio-label">公司(三聯式發票)</label>
                                                </li>
                                                <li class="form__item">
                                                    <input type="radio" class="form__radio" name="inoviceType" id="invoiceDonate"><label for="invoiceDonate" class="form__radio-label">捐贈發票</label>
                                                </li>
                                                <li class="form__item">
                                                    <label for="" class="form__label">公司抬頭</label>
                                                    <input type="text" class="form__input">
                                                </li>
                                                <li class="form__item">
                                                    <label for="" class="form__label">統一編號</label>
                                                    <input type="text" class="form__input">
                                                </li>
                                            </ul>
                                        </div>
                                        <p class="form__under"></p>
                                    </li>
                                    <li class="form__item">
                                        <label for="" class="form__label">寄送地址</label>
                                        <div class="form__multi-blanks">
                                            <ul class="form__list">
                                                <li class="form__item">
                                                    <div class="form__select">
                                                        <div class="custom-select">
                                                            <input class="custom-select__input" type="text" v-model="contry">
                                                            <select class="custom-select__select">
                                                                <option>{{contry}}</option>
                                                                <option>{{contry}}</option>
                                                            </select>
                                                            <i class="fas fa-caret-down"></i>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="form__item">
                                                    <div class="form__select">
                                                        <div class="custom-select">
                                                            <input class="custom-select__input" type="text" v-model="area">
                                                            <select class="custom-select__select">
                                                                <option>{{area}}</option>
                                                                <option>{{area}}</option>
                                                            </select>
                                                            <i class="fas fa-caret-down"></i>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="form__item">
                                                    <input type="text" class="form__input" placeholder="郵遞區號">
                                                </li>
                                                <li class="form__item">
                                                    <input type="text" class="form__input" placeholder="地址">
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="form__item">
                                        <label for="" class="form__label">聯絡電話</label>
                                        <input type="text" class="form__input">
                                    </li>
                                </ul>
                                <p class="button-line button-line--align-center button-line--underform"><a href="" class="btn normal-btn">儲存修改</a></p>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <lightbox box-title="新增信用卡" box-size="large" :show-box="showBox" box-id="addCardBox" @closebox="showBox = 'none'">
            <template slot="msg">
                <credit-card type="box"></credit-card>
            </template>
        </lightbox>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import Vue from 'vue'
    import hero from './../components/hero.vue'
    import lightbox from './../components/lightbox.vue'
    import creditCard from './../components/creditCard.vue'
    export default {
        data(){
            return{
                contry: '縣市',
                area: '鄉鎮市區',
                showBox: 'none'
            }
        },
        components: {
            hero,
            lightbox,
            creditCard
        }
    }
</script>