export const ANALYSIS = "ANALYSIS"
export const COMPETE = "COMPETE"
export const ARTICLE = "ARTICLE"

export const CONTACT = "CONTACT"
export const FAQ = "FAQ"
export const PICK_FAQ = "PICK_FAQ"

export const LOGIN = "LOGIN"
export const REGISTRATION = "REGISTRATION"
export const ACTIVE = "ACTIVE"
export const RESEND_AUTH_CODE = "RESEND_AUTH_CODE"
export const UPLOAD_PHOTO = "UPLOAD_PHOTO"

export const LOADING = "LOADING"
