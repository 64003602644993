import Vue from 'vue';
import * as types from './mutations_type.js';

export const state = {
    loading: false
}

export const getters = {
    getLoading: state => state.loading
}

export const actions = {
    startLoading({commit}){
        commit(types.LOADING, true)
    },
    endLoading({commit}){
        commit(types.LOADING, false)
    }
}

export const mutations = {
    [types.LOADING](state, loading){
        state.loading = loading
        if(loading){
            document.querySelector('.loading').classList.add('active')
        }else{
            document.querySelector('.loading').classList.remove('active')
        }
    }
}